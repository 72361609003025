import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import ProgramDetailsPanel from '../Panels/ProgramDetailsPanel';
import AddWorkoutsPanel from '../Panels/AddWorkoutsPanel';
import { useSelector, useDispatch } from 'react-redux';
import { dragWorkoutToProgramAutoSave, reorderWorkoutAutoSave } from '../../reducers/selectedProgramReducer';
import { fetchWorkouts } from '../../reducers/workoutsReducer';

function ProgramDragDropContext (props) {
  const {
    programDetailsVisible,
    addWorkoutsVisible,
    getProgramUrl,
    resetProgramIndex,
    currentView,
    programListKey,
    programDetailsKey,
    addWorkoutKey,
    programId,
    getWorkoutUrlParams,
    programDetailsLink,
    copyProgramLink,
    backLink,
    updateButtonVisibilityFlags,
    onClickSchedule,
  } = props;

  const dispatch = useDispatch();
  const workouts = useSelector(state => state.workouts.byId);

  useEffect(() => {
    dispatch(fetchWorkouts());
  }, [dispatch]);

  const onDragEnd = (result) => {
    const { source, destination, draggableId: workoutId } = result;

    if (!destination) { return; }

    if ((destination.droppableId === source.droppableId) && (destination.index === source.index)) { return; }

    // Dropping workouts into program workout list
    if (source.droppableId === 'Workout-List') {
      const workout = workouts[workoutId];
      if (workout) {
        dispatch(dragWorkoutToProgramAutoSave({
          workout,
          destinationIndex: destination.index,
        }));
      }
    } else if (source.droppableId === 'Program-Workouts') {
      // Reordering workouts within program
      dispatch(reorderWorkoutAutoSave({
        sourceIndex: source.index,
        destinationIndex: destination.index,
      }));
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ProgramDetailsPanel
        visible={programDetailsVisible}
        programListKey={programListKey}
        addWorkoutKey={addWorkoutKey}
        programId={programId}
        resetProgramIndex={resetProgramIndex}
        currentView={currentView}
        getWorkoutUrlParams={getWorkoutUrlParams}
        copyProgramLink={copyProgramLink}
        backLink={backLink}
        onClickSchedule={onClickSchedule}
        droppable
        updateButtonVisibilityFlags={updateButtonVisibilityFlags}
      />
      <AddWorkoutsPanel
        visible={addWorkoutsVisible}
        programDetailsKey={programDetailsKey}
        getWorkoutUrl={getProgramUrl}
        addWorkoutKey={addWorkoutKey}
        getWorkoutUrlParams={getWorkoutUrlParams}
        programDetailsLink={programDetailsLink}
        updateButtonVisibilityFlags={updateButtonVisibilityFlags}
      />
    </DragDropContext>
  );
}

ProgramDragDropContext.propTypes = {
  programDetailsVisible: PropTypes.bool.isRequired,
  addWorkoutsVisible: PropTypes.bool.isRequired,
  getProgramUrl: PropTypes.func,
  resetProgramIndex: PropTypes.func,
  currentView: PropTypes.string.isRequired,
  programListKey: PropTypes.string,
  programDetailsKey: PropTypes.string,
  addWorkoutKey: PropTypes.string,
  programId: PropTypes.string,
  getWorkoutUrlParams: PropTypes.func,
  programDetailsLink: PropTypes.func,
  copyProgramLink: PropTypes.func,
  backLink: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  updateButtonVisibilityFlags: PropTypes.func,
};

export default ProgramDragDropContext;
