import React from 'react';
import PropTypes from 'prop-types';
import { CardMedia, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { OvalButton } from '../../components/Buttons';
import DialogCircleButton from './DialogCircleButton';

import { ScheduleBannerImages } from './ScheduleBannerImages';
import { ReactComponent as TrashIcon } from '../../resources/icon-trash.svg';
import { ReactComponent as CloseIcon } from '../../resources/icons-global-close-med-gray.svg';
import { ReactComponent as CalendarIcon } from '../../resources/icon-calendar-white.svg';
import { colors } from '../../styles';

const useStyles = makeStyles({
  scheduleBanner: {
    objectFit: 'cover',
    minHeight: 225,
    minWidth: 600,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      content: '""',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  topButtonsContainer: props => ({
    display: 'flex',
    justifyContent: props.hideEditAndDeleteButtons ? 'flex-end' : 'space-between',
    marginTop: 18,
    zIndex: 1,
  }),
  rightButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  workoutTitle: {
    marginLeft: 23,
    fontSize: 24,
    lineHeight: 1.29,
    fontWeight: 500,
    color: colors.white,
    fontFamily: 'Roboto',
    zIndex: 1,
  },
  workoutDuration: {
    marginTop: 17,
    marginBottom: 17,
    marginLeft: 23,
    fontSize: 17,
    fontWeight: 500,
    color: colors.white,
    fontFamily: 'Roboto',
    zIndex: 1,
  },
});

function ScheduleBanner (props) {
  const {
    workoutName,
    programCategoryName,
    timeDisplay,
    eventDate,
    videoWorkoutImage = '',

    onEditSchedule,
    onDeleteSchedule,
    onClose,
    onChangeSchedule,
    isGroup,
  } = props;

  const hideEditAndDeleteButtons = moment(eventDate).isBefore(moment(), 'day');
  const classes = useStyles({ hideEditAndDeleteButtons });

  const WorkoutNameLabel = () => {
    return workoutName ?
      <Typography className={classes.workoutTitle}>
        {workoutName}
      </Typography> : null;
  };

  // Fallback to lifestyle banner if video workout image is missing
  const categoryName =  programCategoryName === 'Video Workout' &&
    videoWorkoutImage.length === 0 ? 'Lifestyle' : 'Lifestyle';

  return (
    <CardMedia
      image={videoWorkoutImage || ScheduleBannerImages[categoryName]}
      className={classes.scheduleBanner}
    >
      {/*<Box className={classes.imageTint} />*/}
      <Box className={classes.topButtonsContainer}>
        {!isGroup ?
          <EditButton isHidden={hideEditAndDeleteButtons} onEditSchedule={onEditSchedule} />
            : null
          }
        <Box className={classes.rightButtons}>
          {!isGroup ?
            <>
              <DialogCircleButton isHidden={hideEditAndDeleteButtons} onClick={onChangeSchedule}>
                <CalendarIcon />
              </DialogCircleButton>
              <DialogCircleButton isHidden={hideEditAndDeleteButtons} onClick={onDeleteSchedule}>
                <TrashIcon />
              </DialogCircleButton>
            </>
            : null
          }
          <DialogCircleButton onClick={onClose}>
            <CloseIcon />
          </DialogCircleButton>
        </Box>
      </Box>
      <WorkoutNameLabel />
      <Typography className={classes.workoutDuration}>
        {`${timeDisplay} | ${programCategoryName}`}
      </Typography>
    </CardMedia>
  );
}

const useButtonOverlay = makeStyles({
  root: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    margin: '0 0 0 23px',
    width: 75,
    height: 36,
    borderRadius: 19,
    backgroundColor: colors.white,
    cursor: 'pointer',
    zIndex: 1,
    opacity: 0,
    '&:hover': {
      opacity: 0.06,
    },
  },
});

/**
 * @return {null | JSX}
 */
function EditButton (props) {
  const { isHidden, onEditSchedule } = props;
  const classes = useButtonOverlay();

  if (isHidden) {
    return null;
  }

  return (
    <Box className={classes.root} onClick={onEditSchedule}>
      <Box className={classes.overlay} />
      <OvalButton
        margin='0 0 0 23px'
        fontWeight='bold'
        fontSize={19}
        width={75}
        height={36}
        backgroundcolor='transparent'
        color={colors.white}
      >
        Edit
      </OvalButton>
    </Box>
  );
}

ScheduleBanner.propTypes = {
  workoutName: PropTypes.string,
  /***
   * Determines which image banner to display as a scheduled program
   */
  programCategoryName: PropTypes.string,
  timeDisplay: PropTypes.string,
  /***
   * Moment date object
   */
  eventDate: PropTypes.object,
  /***
   * Image Banner pulled from Video Workout Thumbnail
   */
  videoWorkoutImage: PropTypes.string,

  onEditSchedule: PropTypes.func,
  onDeleteSchedule: PropTypes.func,
  onClose: PropTypes.func,
  onChangeSchedule: PropTypes.func,
};

export default ScheduleBanner;
