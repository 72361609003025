import React from 'react';
import OvalButton from './OvalButton';

import { colors } from '../../styles';

export default function FilterButton (props) {
  const { onOpenFilterMenu, filterCount, isPressed, filterLabel = 'Filter', ...rest } = props;
  const filterActive = filterCount > 0 || isPressed;
  return (
    <OvalButton
      {...rest}
      backgroundcolor={filterActive ? colors.navy : colors.white}
      color={filterActive ? colors.white : colors.steel}
      onClick={onOpenFilterMenu}
      border={filterActive ? 'none' : null} // null: default to standard oval button border
    >
      {filterCount > 0 ? filterCount : filterLabel}
    </OvalButton>
  );
}
