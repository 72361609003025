import { createSlice } from "@reduxjs/toolkit";
import nasm from "../dataManager/apiConfig";
import nasmApi from "../api/endpoints/index";
import { normalize, denormalize, schema } from "normalizr";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { programContexts } from "./programContextReducer";
import { possessiveName } from "./../util/validate";
import { FEATURE_FLAGS } from "../constants";
import { formatSuperSets, unformatSuperSets } from "../util/programUtils";
// *****
// Normalizr Schemas
// *****
const workoutSchema = new schema.Entity("workouts", {}, { idAttribute: "key" });
const programSchema = { workouts: [workoutSchema] };

const initialState = {
  loading: false,
  error: false,
  programChanged: false,
  workoutAdded: false,
  // Duplicate might not be needed, there is a dedicated API route for this
  duplicate: false,
  newProgram: false,
  // is_visible: true,
  editable: true,
  correctiveExercises: [],
  program: null,
  entities: null,
};

export const selectedProgramSlice = createSlice({
  name: "selectedProgram",
  initialState,
  reducers: {
    // -----
    // ERROR RECEIVED
    programErrorReceived: (state, action) => {
      const response = action.payload;
      state.loading = false;
      if (response.error) {
        state.error = response.error;
      }
    },
    // -----
    // GET WORKOUT DETAILS
    programDetailsRequested: (state, action) => {
      const { programId, scheduleId, editable } = action.payload;
      return {
        ...initialState,
        loading: true,
        error: false,
        programId,
        scheduleId,
        editable: editable,
      };
    },
    programDetailsReceived: (state, action) => {
      const response = action.payload;
      state.loading = false;
      state.programChanged = false;
      state.workoutAdded = false;
      const newWorkouts = formatSuperSets(response.workouts);
      const newProgram = {
        ...response,
        workouts: newWorkouts,
      };
      const keyedProgram = addWorkoutKeys(newProgram);
      const { result, entities } = normalize(keyedProgram, programSchema);
      state.program = result;
      state.entities = {
        workouts: entities.workouts || {},
      };
    },
    // -----
    // SAVE PROGRAM
    saveProgramRequested: (state, action) => {
      state.loading = true;
      state.program.name = action.payload.name;
    },
    saveProgramRecevied: (state, action) => {
      const response = action.payload;
      state.loading = false;
      if (response.error) {
        state.error = response;
      } else {
        state.programChanged = false;
        state.workoutAdded = false;
      }
    },
    // -----
    // SCHEDULE PROGRAM
    scheduleProgramRequested: (state, action) => {
      state.loading = true;
      state.program.name = action.payload.programName || action.payload.name;
    },
    scheduleProgramReceived: (state, action) => {
      const response = action.payload;
      state.loading = false;
      if (response.error) {
        state.error = response.error;
      } else {
        state.programChanged = false;
        state.workoutAdded = false;
      }
    },
    // -----
    // DUPLICATE PROGRAM
    duplicateProgramRequested: (state) => {
      state.duplicate = true;
      state.loading = true;
    },
    duplicateProgramReceived: (state, action) => {
      const response = action.payload;
      const keyedProgram = addWorkoutKeys(response);
      const { result, entities } = normalize(keyedProgram, programSchema);
      state.duplicate = true;
      state.loading = false;
      state.editable = true;
      state.programChanged = false;
      state.workoutAdded = false;
      state.program = result;
      state.entities = {
        workouts: entities.workouts || {},
      };
    },
    // -----
    // ADD WORKOUTS
    workoutsRequested: (state) => {
      state.loading = true;
    },
    addWorkouts: (state, action) => {
      const workouts = action.payload;
      const correctiveExercises = state.correctiveExercises;

      state.loading = false;
      state.programChanged = true;
      state.workoutAdded = true;
      workouts.forEach((workout) => {
        const key = workout.key || uuidv4();
        const correctiveWorkout = state.correctiveExercises.length
          ? addCorrectiveExercisesToWorkout(workout, correctiveExercises)
          : workout;
        state.program.workouts.push(key);
        state.entities.workouts[key] = { key, ...correctiveWorkout };
      });
    },
    addWorkoutToTop: (state, action) => {
      const workout = action.payload;
      const correctiveExercises = state.correctiveExercises;

      state.loading = false;
      state.workoutAdded = true;

      const key = workout.id || uuidv4();
      const correctiveWorkout = state.correctiveExercises.length
        ? addCorrectiveExercisesToWorkout(workout, correctiveExercises)
        : workout;
      state.program.workouts.unshift(key);
      state.entities.workouts[key] = { key, ...correctiveWorkout };

      const workoutKeys = Object.keys(state.entities.workouts);
      const newWorkouts = {};
      workoutKeys.unshift(key);
      workoutKeys.forEach((workoutKey) => {
        newWorkouts[workoutKey] = state.entities.workouts[workoutKey];
      });
      state.entities.workouts = newWorkouts;
      state.programChanged = true;
    },
    // -----
    // LOCAL STATE UPDATES
    assignExercises: (state) => {
      state.is_visible = false;
    },
    editProgramName: (state, action) => {
      state.programChanged = true;
      state.program.name = action.payload;
    },
    editProgramCategory: (state, action) => {
      state.programChanged = true;
      state.program.program_category = action.payload;
    },
    editWorkout: (state, action) => {
      const workout = action.payload;

      state.programChanged = true;
      state.entities.workouts[workout.key] = {
        ...workout,
        exercise_count: countExercises(workout),
      };
    },
    removeWorkout: (state, action) => {
      const workout = action.payload;
      const { workouts } = state.program;
      const index = workouts.findIndex((item) => item === workout.key);
      workouts.splice(index, 1);
      delete state.entities.workouts[workout.key];
      state.programChanged = true;
    },
    sortWorkouts: (state, action) => {
      const newWorkouts = action.payload;
      state.program.workouts = newWorkouts;
      state.programChanged = true;
    },
    dragWorkoutToProgram: (state, action) => {
      const { workout, destinationIndex } = action.payload;
      const key = uuidv4();
      const newWorkouts = {};
      state.entities.workouts[key] = { key, ...workout };
      const workoutKeys = Object.keys(state.entities.workouts);
      workoutKeys.splice(destinationIndex, 0, key);
      workoutKeys.forEach((workoutKey) => {
        newWorkouts[workoutKey] = state.entities.workouts[workoutKey];
      });
      state.entities.workouts = newWorkouts;
      state.program.workouts.push(key);
      state.programChanged = true;
    },
    reorderWorkouts: (state, action) => {
      const { sourceIndex, destinationIndex } = action.payload;
      const workoutKeys = Object.keys(state.entities.workouts);
      const newWorkouts = {};
      const [selectedWorkoutId] = workoutKeys.splice(sourceIndex, 1);
      workoutKeys.splice(destinationIndex, 0, selectedWorkoutId);
      workoutKeys.forEach((workoutKey) => {
        newWorkouts[workoutKey] = state.entities.workouts[workoutKey];
      });
      state.entities.workouts = newWorkouts;
      state.programChanged = true;
    },
    clearProgram: () => initialState,
    createNewProgram: (state, action) => {
      const { result, entities } = normalize(action.payload, programSchema);
      state.loading = false;
      state.error = false;
      state.newProgram = true;
      state.programChanged = false;
      state.workoutAdded = false;
      state.program = result;
      state.entities = {
        workouts: entities.workouts || {},
      };
    },
    selectWorkoutDay: (state, action) => {
      const { workoutKey, dayId } = action.payload;
      const workouts = state.entities.workouts;
      const workout = workouts[workoutKey];

      if (!workout.dayOfWeek) {
        workout.dayOfWeek = [dayId];
      } else {
        const index = workout.dayOfWeek.indexOf(dayId);
        if (index >= 0) {
          // TODO: do we need to sort workout days?
          workout.dayOfWeek.splice(index, 1);
        } else {
          workout.dayOfWeek.push(dayId);
        }
        workout.dayOfWeek = workout.dayOfWeek.sort();
      }
    },
    setWorkoutDays: (state, action) => {
      const { workoutKey, days = [] } = action.payload;
      const workouts = state.entities.workouts;
      const workout = workouts[workoutKey];

      workout.dayOfWeek = days.sort();
    },
    deleteScheduleRequested: (state) => {
      state.loading = true;
    },
    deleteScheduleSuccess: () => ({
      ...initialState,
      loading: true,
    }),
    addCorrectiveExercises: (state, action) => {
      const correctiveExercises = action.payload;
      // We save the selected exercises to state in case we add another workout
      state.correctiveExercises = correctiveExercises;
      Object.keys(state.entities.workouts).forEach((key) => {
        const workout = state.entities.workouts[key];
        const newWorkout = addCorrectiveExercisesToWorkout(
          workout,
          correctiveExercises,
        );
        state.entities.workouts[key] = newWorkout;
      });
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = selectedProgramSlice;
// Extract and export each action creator by name
export const {
  editProgramName,
  editProgramCategory,
  editWorkout,
  removeWorkout,
  sortWorkouts,
  clearProgram,
  selectWorkoutDay,
  setWorkoutDays,
  addCorrectiveExercises,
  addWorkoutToTop,
  addWorkouts: addWorkoutsNoBackEndApi,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;

// *****
// ACTION CREATORS
// *****

// -----
// SELECT PROGRAM
export const selectProgram = (program) => async (dispatch, getState) => {
  const { id: programId } = program;
  const { currentUser, programContext } = getState();
  const userId = currentUser.id;
  const editable =
    programContext.context === programContexts.SCHEDULING ||
    programContext.context === programContexts.RESCHEDULING ||
    userId === program.owner_id;

  dispatch(
    actions.programDetailsRequested({
      programId,
      scheduleId: program.scheduleId || null,
      editable,
    }),
  );

  const response = await nasm.api
    .getProgramById(programId)
    .catch((error) => ({ error: error.message }));

  if (response.error) {
    dispatch(actions.programErrorReceived(response));
    return Promise.reject(response.error);
  }

  dispatch(actions.programDetailsReceived(response));
  return Promise.resolve(response);
};
// -----
// SELECT SCHEDULED PROGRAM
export const selectScheduledProgram = (scheduleDay) => async (
  dispatch,
  getState,
) => {
  const { program_id: programId, schedule_id: scheduleId, programStartDate, programEndDate } = scheduleDay;
  const { selectedClient } = getState();
  const clientId = selectedClient.client.id;

  dispatch(
    actions.programDetailsRequested({ programId, scheduleId, editable: true }),
  );

  const programRequest = nasmApi.nasmPrograms.getProgram(programId);
  const scheduleRequest = nasmApi.workoutSchedule.getUserAssignedProgramDetails({
    userId: clientId,
    scheduleId,
    program_start_date: programStartDate,
    program_end_date: programEndDate,
  });
  const response = await Promise.all([
    programRequest,
    scheduleRequest,
  ]).catch((error) => ({ error: error.data.message }));

  if (response.error) {
    dispatch(actions.programErrorReceived(response));
    return Promise.reject(response.error);
  }

  // We have to combine the program details and schedule details to get all the properties
  const [programResult, scheduleResult] = response;
  const programDetails = programResult.result;

  // Make all sections without an exercises array default to an empty one
  const scheduleDetails = {
    ...scheduleResult.result,
    workouts: scheduleResult.result.workouts.map((workout) => ({
      ...workout,
      sections: workout.sections.map((section) => ({
        ...section,
        exercises: section.exercises || [],
      })),
    })),
  };

  const programData = {
    ...programDetails,
    ...scheduleDetails,
  };

  dispatch(actions.programDetailsReceived(programData));
  return Promise.resolve(programData);
};
// -----
// CREATE PROGRAM
export const createNewProgram = () => (dispatch) => {
  const blankProgram = {
    name: null,
    id: null,
    key: uuidv4(),
    program_category: null,
    workouts: [],
  };
  dispatch(actions.createNewProgram(blankProgram));
};
// -----
// DUPLICATE PROGRAM
export const duplicateProgram = (programId) => async (dispatch, getState) => {
  const id = programId || getState().selectedProgram.id;
  dispatch(actions.duplicateProgramRequested(id));
  const response = await nasmApi.nasmPrograms
    .duplicateProgram(id)
    .catch((error) => ({ error: error.message }));
  if (response.error) {
    dispatch(actions.programErrorReceived(response.result));
    return Promise.reject(response.error);
  }
  dispatch(actions.duplicateProgramReceived(response.result));
  return Promise.resolve(response.result);
};
// -----
// SAVE PROGRAM
export const saveProgram = () => async (dispatch, getState) => {
  const { selectedProgram, programContext } = getState();
  const { program, entities, loading } = selectedProgram;
  const { RESCHEDULING } = programContexts;
  if (loading) return;

  // Call reschedule endpoint if we're editing a scheduled program
  const startDate = moment(program.start_date).isBefore(moment())
    ? moment()
    : moment(program.start_date);
  if (programContext.context === RESCHEDULING) {
    await dispatch(
      scheduleProgram(
        startDate.format(),
        moment(program.end_date).endOf('day').format(),
        program.start_date,
        program.end_date,
      ),
    );
    return;
  }

  // Program name can be null for quick adds or defaulted for auto save from library
  const name =
    program.name ||
    (programContext.context === RESCHEDULING
      ? null
      : "New Program " + moment().format("MM-DD"));

  // Format request body
  const requestBody = {
    id: program.id || null,
    name,
    workout_ids: Object.values(entities.workouts).map((workout) => workout.id),
    nasm_program_category_id: program.program_category?.id,
  };

  dispatch(actions.saveProgramRequested(requestBody));

  // Save or Create program
  let request;
  if (program.id) {
    request = nasmApi.nasmPrograms.updateProgram(program.id, requestBody);
  } else {
    request = nasmApi.nasmPrograms.createProgram(requestBody);
  }

  // Handle response/error
  const response = await request.catch((error) => ({
    error: error.data.message,
  }));
  if (response.error) {
    dispatch(actions.programErrorReceived(response.error));
    return Promise.reject(response.error);
  } else {
    dispatch(actions.saveProgramRecevied(response?.result ?? response));
    dispatch(actions.programDetailsReceived(response?.result ?? response));
    return Promise.resolve(response.result);
  }
};
// -----
// ADD WORKOUTS
export const addWorkouts = (workouts) => async (dispatch, getState) => {
  const { programContext } = getState();
  const workoutIds = workouts.map((workout) => workout.id);
  dispatch(actions.workoutsRequested(workoutIds));
  const response = await nasm.api
    .getBatchWorkoutsByIds(workoutIds)
    .catch((error) => ({ error: error.message }));
  if (response.error) {
    return Promise.reject(response);
  }
  // if a key was already set for the workouts, add it back is
  workouts.forEach((workout) => {
    if (workout.key) {
      response.find((item) => item.id === workout.id).key = workout.key;
    }
  });
  dispatch(actions.addWorkouts(response));
  if (programContext.context === programContexts.LIBRARY) {
    await dispatch(saveProgram());
  }
  return Promise.resolve(response);
};

export const addWorkoutToTopAndSave = (workout) => async (
  dispatch,
  getState,
) => {
  const { programContext } = getState();
  dispatch(actions.addWorkoutToTop(workout));
  if (programContext.context === programContexts.LIBRARY) {
    await dispatch(saveProgram());
  }
};
// -----
// QUICK ADD EXERCISES
export const assignExercises = () => (dispatch, getState) => {
  const state = getState();
  const { workouts, selectedClient, selectedGroup } = state;
  const { defaultSections } = workouts;
  const isGroup = selectedGroup?.group?.id;
  let clientOrGroupName = "";
  if (selectedClient?.client?.id) {
    clientOrGroupName = possessiveName(selectedClient?.client?.first_name);
  } else if (isGroup) {
    clientOrGroupName = selectedGroup?.group?.title;
  }
  // const clientName = possessiveName(state.selectedClient?.client?.first_name);
  const blankSections = defaultSections.map((section) => ({
    ...section,
    exercises: [],
  }));
  const newWorkout = {
    name: `${clientOrGroupName} Workout`,
    id: null,
    key: uuidv4(),
    is_visible: false,
    sections: blankSections,
  };
  dispatch(createNewProgram());
  dispatch(actions.addWorkouts([newWorkout]));
  const workout = getState().selectedProgram.entities.workouts[newWorkout.key];
  dispatch(actions.assignExercises(workout));
};
// -----
// SCHEDULE PROGRAM
export const scheduleProgram = (
  startDate,
  endDate,
  oldStartDate,
  oldEndDate,
) => async (dispatch, getState) => {
  const {
    selectedProgram,
    selectedClient,
    selectedGroup,
    programContext,
    clubConnect,
  } = getState();
  const isGroup = selectedGroup?.group?.id;
  const { program: result, entities } = selectedProgram;
  const program = denormalize(result, programSchema, entities);
  const isNew = programContext.context === programContexts.SCHEDULING;
  const selectedProfile = clubConnect?.selectedProfile;

  // Only include user_id and alias_name for each exercise object containing an alias
  program.workouts = program.workouts.reduce((workoutsAcc, workout) => {
    const dayOfWeek = workout?.dayOfWeek?.map((day) => day.toString());
    workout = { ...workout, dayOfWeek };
    const sections = workout.sections.reduce((sectionsAcc, section) => {
      const exerciseMappings = section.exercises.reduce(
        (exercisesAcc, exercise) => {
          if (exercise.alias_name) {
            const aliasMappings = exercise.alias_name.map((alias) => ({
              user_id: alias.user_id,
              alias_name: alias.alias_name,
            }));
            return [
              ...exercisesAcc,
              { ...exercise, alias_name: aliasMappings },
            ];
          }

          return [...exercisesAcc, { ...exercise }];
        },
        [],
      );

      return [...sectionsAcc, { ...section, exercises: exerciseMappings }];
    }, []);

    return [...workoutsAcc, { ...workout, sections }];
  }, []);

  // Name the program for quick add workouts with multiple workouts
  let clientOrGroupName = "";
  if (selectedClient?.client?.id) {
    const clientName = possessiveName(selectedClient?.client?.first_name);
    clientOrGroupName = `${clientName} Program`;
  } else if (isGroup) {
    clientOrGroupName = `${selectedGroup?.group?.title} Program`;
  }
  const defaultProgramName =
    program.workouts.length > 1 ? clientOrGroupName : null;
  const programName = program.name || defaultProgramName;
  // Update startDate to make sure we do not set a schedule before today
  const newStartDate = moment(startDate).isBefore(moment())
    ? moment().format()
    : startDate;

  // If dayOfWeek array is missing from any workouts inside of program, backfill it with data
  // from days_of_week to ensure backend can properly reschedule the program
  // e.g. bug encountered whenever a scheduled workout name is being changed
  if (program.workouts && program.workouts instanceof Array) {
    program.workouts = program.workouts.map((w) => {
      if (!!w.dayOfWeek === false && !!w.days_of_week) {
        return {
          ...w,
          dayOfWeek: w.days_of_week,
        };
      }

      return w;
    });
  }

  // Default to scheduled workout_name value when rescheduling a program
  // This prevents scheduled workouts from being renamed back to their template workout name
  if (programContext.context === programContexts.RESCHEDULING) {
    program.workouts = program.workouts.map((w) => {
      if (!!w.workout_name) {
        return {
          ...w,
          name: w.workout_name,
        };
      }

      return w;
    });
  }

  const newWorkouts = unformatSuperSets(program.workouts);

  // Gather data
  const programBody = {
    startDate: newStartDate,
    endDate,
    oldStartDate,
    oldEndDate,
    programName,
    workouts: newWorkouts,
    nasmProgramId: isNew ? program.id : program.nasm_program_id,
  };

  if (selectedProfile?.ClubId) {
    programBody.club_id = selectedProfile?.ClubId;
    if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && selectedProfile?.Locations?.Id) {
      programBody.location_id = selectedProfile?.Locations?.Id;
    }
  }

  const analyticInfo = {
    start_date: newStartDate,
    end_date: endDate,
  };
  if (selectedClient?.client?.id) {
    analyticInfo.client_id = selectedClient?.client?.id;
  } else if (isGroup) {
    analyticInfo.group_id = selectedGroup?.group?.id;
  }
  if (program.owner_id !== undefined) {
    analyticInfo.custom_program = program.owner_id === null ? "false" : "true";
  }
  if (program.id) {
    analyticInfo.program_id = program.id;
  }
  // Make request
  let request;
  if (isNew) {
    if (selectedClient?.client?.id) {
      request = nasm.api.scheduleClientProgram(
        selectedClient?.client?.id,
        programBody,
      );
    } else if (isGroup) {
      request = nasm.api.scheduleForGroup(
        selectedGroup?.group?.id,
        programBody,
      );
    }
  } else {
    request = nasm.api.rescheduleClientProgram(
      selectedClient?.client?.id,
      program.id,
      programBody,
    );
  }

  dispatch(
    actions.scheduleProgramRequested({
      ...programBody,
      isNew,
    }),
  );

  const response = await request.catch((error) => ({
    error: {
      name: error.name || error?.statusText || "Error",
      message: error.message || error?.data?.message || "Unexpected Error",
    },
  }));

  if (!response || response.error) {
    dispatch(actions.programErrorReceived(response));
    return Promise.reject(response.error ?? response);
  }

  // Successful response
  dispatch(actions.scheduleProgramReceived(response.result ?? response));
  return Promise.resolve(response.result ?? response);
};
// -----
// QUICK ADD - SCHEDULE PROGRAM TODAY
export const scheduleProgramToday = (day) => async (dispatch, getState) => {
  const state = getState();
  const today = moment(day).format();
  const dayId = moment(today).format("d");
  const workoutKeys = state.selectedProgram.program.workouts;
  workoutKeys.forEach((workoutKey) => {
    dispatch(
      actions.selectWorkoutDay({
        workoutKey,
        dayId: Number.parseInt(dayId, 10),
      }),
    );
  });
  return dispatch(scheduleProgram(today, moment(today).endOf("day").format()));
};
// -----
// REMOVE SCHEDULED PROGRAM
export const deleteScheduledProgram = (programId = null) => async (
  dispatch,
  getState,
) => {
  const { selectedProgram, selectedClient } = getState();
  const scheduleId = programId || selectedProgram.program.id;
  const userId = selectedClient.client.id;
  const currentDate = moment().format();

  dispatch(actions.deleteScheduleRequested());

  const response = await nasmApi.workoutSchedule
    .deleteClientProgram(userId, scheduleId, currentDate)
    .catch((error) => ({ error: error.message }));
  if (response.error) {
    dispatch(actions.programErrorReceived({ error: response.error.message }));
    return Promise.reject(response.error);
  }
  dispatch(actions.deleteScheduleSuccess(response.result));
  return Promise.resolve(response.result);
};

export const dragWorkoutToProgramAutoSave = ({
  workout,
  destinationIndex,
}) => async (dispatch) => {
  dispatch(actions.dragWorkoutToProgram({ workout, destinationIndex }));
  return dispatch(saveProgram());
};

export const reorderWorkoutAutoSave = ({
  sourceIndex,
  destinationIndex,
}) => async (dispatch) => {
  dispatch(actions.reorderWorkouts({ sourceIndex, destinationIndex }));
  return dispatch(saveProgram());
};

// *****
// HELPER FUNCTIONS
// *****
const countExercises = (workout) => {
  const exercises = workout.sections.reduce((exercises, section) => {
    return exercises.concat(section.exercises);
  }, []);
  return exercises.length;
};

const addCorrectiveExercisesToWorkout = (workout, correctiveExercises) => ({
  ...workout,
  exercise_count: workout.exercise_count + correctiveExercises.length,
  sections: workout.sections.map((section) => {
    const newExercises = correctiveExercises.filter(
      (exercise) => exercise.sectionId === section.id,
    );
    if (newExercises.length === 0) return section;
    newExercises.forEach((exercise) => {
      delete exercise.sectionId;
    });
    return {
      ...section,
      exercises: newExercises.concat(section.exercises),
    };
  }),
});

const addWorkoutKeys = (program) => ({
  ...program,
  workouts: (program.workouts || []).map((workout) => ({
    ...workout,
    key: workout.id || uuidv4(),
  })),
});
