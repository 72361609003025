import React, {useEffect, useState} from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import queryString from 'query-string';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Droppable } from 'react-beautiful-dnd';

import { OvalButton, NavyButton, MacaroniButton, SaveButton } from '../Buttons';

import RowPlaceholder from '../WorkoutSections/RowPlaceholder';
import { DraggableExerciseRow, ExerciseRow } from '../WorkoutSections/ExerciseRow';
import { UnderlineTextInput } from '../Inputs';
import SectionDivider from '../WorkoutSections/SectionDivider';
import ScheduleHeader from '../Schedule/ScheduleHeader';
import Panel from './Panel';

import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';
import FadeTransition from '../Transitions/FadeTransition';

import { colors } from '../../styles';
import { calculateSecondsForExercise, getDurationFromSeconds } from '../../util/programUtils';
import { SAVE_STATES } from '../../constants';

import {
  editWorkoutName,
  fetchAndCopyWorkout,
  saveWorkout,
  editScheduledWorkoutName, selectWorkout,
} from '../../reducers/selectedWorkoutReducer';

import { addWorkoutToTopAndSave } from '../../reducers/selectedProgramReducer';

import { programContexts } from '../../reducers/programContextReducer';
const {
  SCHEDULING: SCHEDULE_CONTEXT,
  RESCHEDULING: RESCHEDULING_CONTEXT,
  LIBRARY: LIBRARY_CONTEXT,
} = programContexts;

const DroppableContainer = React.forwardRef((props, ref) => {
  const {
    droppableId,
    workout,
    editExerciseLink,
    viewExerciseLink,
    exercises = [],
    isDragDisabled,
    isEditable,
    selectedExerciseIndex,
    setSelectedExerciseIndex,
    viewSuperSetsLink,
    setSuperSetData,
    viewCircuitLink,
    setCircuitsData,
  } = props;

  return (
    <Droppable droppableId={droppableId}>
      {(provided) => {
        return (
          <Box
            style={{ minHeight: '80px' }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {exercises.length === 0 && <RowPlaceholder />}
            {exercises.map((exercise, index) => {
              return (
                <DraggableExerciseRow
                  key={exercise.key}
                  draggableId={exercise.key}
                  sectionId={droppableId}
                  index={index}
                  workout={workout}
                  exercise={exercise}
                  editExerciseLink={editExerciseLink}
                  viewExerciseLink={viewExerciseLink}
                  isDragDisabled={isDragDisabled}
                  isEditable={isEditable}
                  isSelected={selectedExerciseIndex === exercise.key}
                  setSelectedExerciseIndex={setSelectedExerciseIndex}
                  viewSuperSetsLink={viewSuperSetsLink}
                  setSuperSetData={setSuperSetData}
                  viewCircuitLink={viewCircuitLink}
                  setCircuitsData={setCircuitsData}
                />
              );
            })}
            {provided.placeholder}
          </Box>
        );
      }}
    </Droppable>
  );
});

function SectionsContainer (props) {
  const {
    viewExerciseLink,
    exercises = [],
    selectedExerciseIndex,
    setSelectedExerciseIndex,
    viewSuperSetsLink,
    setSuperSetData,
    viewCircuitLink,
    setCircuitsData,
  } = props;

  return (
    <Box style={{ minHeight: '80px' }}>
      {exercises.length === 0 && <RowPlaceholder />}
      {exercises.map(exercise => {
        return (
          <ExerciseRow
            key={exercise.key}
            exercise={exercise}
            viewExerciseLink={viewExerciseLink}
            isSelected={selectedExerciseIndex === exercise.key}
            setSelectedExerciseIndex={setSelectedExerciseIndex}
            viewSuperSetsLink={viewSuperSetsLink}
            setSuperSetData={setSuperSetData}
            viewCircuitLink={viewCircuitLink}
            setCircuitsData={setCircuitsData}
          />
        );
      })}
    </Box>
  );
}

const useWorkoutSectionsListStyle = makeStyles({
  root: {
    height: '74%',
    overflowY: 'auto',
  },
});

const useWorkoutInputContainerStyle = makeStyles({
  root: {
    padding: '0px 20px 20px 20px',
  },
  text: {
    color: colors.steel,
    fontWeight: 500,
    fontSize: 14,
    marginBottom: '13px',
  },
  hintText: {
    color: colors.steel,
    fontWeight: 500,
    fontSize: 12,
    marginLeft: 10,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 20px 0px 0px',
  },
  buttonsSpacing: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

function WorkoutInputContainer (props) {
  const classes = useWorkoutInputContainerStyle();
  const {
    workout,
    resetWorkoutIndex,
    isEditable,
    refreshWorkouts,

    // Navigation
    addExercisesKey,
    addExercisesListLink,
    copyWorkoutLink,
    backLink,

    // Button Visibility Flags
    currentView,
    workoutButtonsVisibilityFlags,
    updateButtonVisibilityFlags,

    // Scheduling Context Props
    onOpenScheduleDialog,
    isScheduleDialogOpen,
  } = props;

  const {
    showCopyButton,
    showSaveButton,
    showAddExercisesButton,
    showScheduleButton,
    isAutoSaveEnabled,
  } = workoutButtonsVisibilityFlags;

  const dispatch = useDispatch();
  const history = useHistory();

  const program = useSelector(state => state.selectedProgram.program);
  const programContext = useSelector(state => state.programContext.context);
  const [saveState, setSaveState] = useState(SAVE_STATES.CLICKABLE);

  const workoutName = workout.workout_name || workout.name;

  const canAddExercises = program ? !!program?.workouts?.find((workoutId) => workoutId === workout.id) : true;

  function onNameChanged (e) {
    if(programContext === LIBRARY_CONTEXT) {
      dispatch(editWorkoutName(e.target.value));
    } else if([SCHEDULE_CONTEXT, RESCHEDULING_CONTEXT].includes(programContext)) {
      dispatch(editScheduledWorkoutName(e.target.value));
    }
  }

  function onNameEditFinished () {
    if (!!workoutName && workoutName.length > 0) {

      if(programContext === LIBRARY_CONTEXT) {
        dispatch(editWorkoutName(workout.name));
      } else if([SCHEDULE_CONTEXT, RESCHEDULING_CONTEXT].includes(programContext)) {
        dispatch(editScheduledWorkoutName(workout.workout_name));
      }

      if (isAutoSaveEnabled) {
        dispatch(saveWorkout());
        if (refreshWorkouts) {
          refreshWorkouts();
        }
      }
    }
  }

  function onCopyPressed () {
    updateButtonVisibilityFlags({
      isSaveButtonVisible: true,
      isCopyButtonVisible: false,
      isAddExercisesButtonVisible: true,
      isAddButtonVisible: false,
    });
    history.replace(copyWorkoutLink());
    dispatch(fetchAndCopyWorkout(workout.id));
  }

  function onSavePressed () {
    setSaveState(SAVE_STATES.LOADING);

    // This is an async thunk that eventually resolves or rejects
    // window.alert() function blocks the main thread, so the code should display the alert AFTER
    // the workout is successfully saved to the backend
    dispatch(saveWorkout()).then(() => {
      window.alert(`${workout.name} saved successfully to My Workouts`);
      setSaveState(SAVE_STATES.SAVED);
      refreshWorkouts();
      if (history.location.pathname.includes('programs')) {
        history.replace(backLink());
      } else {
        history.replace('/libraries/workouts/my-workouts', { workoutDuplicateName: workout.name });
      }
    }).catch(e => {
      setSaveState(SAVE_STATES.ERROR);
      window.alert(`${workout.name} was not saved to My Workouts`);
      console.error(JSON.stringify(e, null, 2));
    });
  }

  function onAddPressed () {
    dispatch(addWorkoutToTopAndSave(workout)).then(() => {
      alert(`Successfully saved "${workout.name}" to your program`);
    }).catch(() => {
      alert(`Could not save "${workout.name}" to your program`);
    });
  }

  function onSchedulePressed () {
    if (programContext === SCHEDULE_CONTEXT) {
      onOpenScheduleDialog();
    }
  }

  function onClose () {
    resetWorkoutIndex();
  }

  return (
    <>
      <Box className={classes.buttonsContainer}>
        {showSaveButton && !history.location?.state?.addingWorkouts &&
          <Box style={{ paddingRight: 5 }}>
            <SaveButton
              saveState={saveState}
              onClick={onSavePressed}
            />
          </Box>}
        {showCopyButton &&
          <Box style={{ paddingRight: 5 }}>
            <MacaroniButton onClick={onCopyPressed}>Copy</MacaroniButton>
          </Box>}
        {history.location?.state?.addingWorkouts &&
          <Box style={{ paddingRight: 5 }}>
            <MacaroniButton onClick={onAddPressed}>Add</MacaroniButton>
          </Box>}
        {showScheduleButton &&
          <Box style={{ paddingRight: 5 }}>
            <OvalButton
              border='none'
              color={colors.white}
              width={95}
              backgroundcolor={isScheduleDialogOpen
                ? colors.navy : colors.yellow_button_style}
              disabled={isScheduleDialogOpen}
              onClick={onSchedulePressed}
            >
              Schedule
            </OvalButton>
          </Box>}
        <Box style={{ paddingRight: showSaveButton || showCopyButton ? 5 : 0 }}>
          <Link
            onClick={onClose}
            to={backLink()}
          >
            <OvalButton>Close</OvalButton>
          </Link>
        </Box>
      </Box>
      <Box className={classes.root}>
        {showScheduleButton &&
          <ScheduleHeader>
            Workout Details.
          </ScheduleHeader>}
        <Typography className={classes.text}>
          Workout Name
        </Typography>
        <UnderlineTextInput
          value={workoutName || ''}
          placeholder='Enter Workout Name'
          onNameChanged={onNameChanged}
          onNameEditFinished={onNameEditFinished}
          readOnly={!isEditable}
        />
        {showAddExercisesButton &&
          <Box style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
            {currentView !== addExercisesKey &&
              <Link
                to={{
                  search: addExercisesListLink(),
                }}
                onClick={(e) => {
                  if (!canAddExercises) {
                    e.preventDefault();
                  }
                }}
              >
                <MacaroniButton
                  active={!canAddExercises}
                  disabled={!canAddExercises}
                  width={144}
                  height={36}
                >
                  Add Exercises
                </MacaroniButton>
              </Link>}
              {!canAddExercises ? (
                <Typography className={classes.hintText}>
                  Add this workout to the program before adding exercises.
                </Typography>
              ) : null}
            {currentView === addExercisesKey &&
              <NavyButton width={144} height={36}>
                Add Exercises
              </NavyButton>}
          </Box>}
      </Box>
    </>
  );
}

function DragNDropWorkoutSectionsList (props) {
  const classes = useWorkoutSectionsListStyle();

  const {
    editExerciseLink,
    viewExerciseLink,
    exercisesBySection = {},
    workout = {},
    workoutSections = [],
    isDragDisabled,
    isEditable,
    selectedExerciseIndex,
    setSelectedExerciseIndex,
    viewSuperSetsLink,
    viewCircuitLink,
    setSuperSetData,
    setCircuitsData,
  } = props;
  return (
    <Box className={classes.root}>
      {
        workoutSections.map((section, index) => (
          <React.Fragment key={index}>
            <SectionDivider name={section.name} totalTime={section.totalTimeStr} />
            <DroppableContainer
              droppableId={section.id}
              workout={workout}
              exercises={exercisesBySection[section.id] || []}
              editExerciseLink={editExerciseLink}
              viewExerciseLink={viewExerciseLink}
              isDragDisabled={isDragDisabled}
              isEditable={isEditable}
              selectedExerciseIndex={selectedExerciseIndex}
              setSelectedExerciseIndex={setSelectedExerciseIndex}
              viewSuperSetsLink={viewSuperSetsLink}
              viewCircuitLink={viewCircuitLink}
              setSuperSetData={setSuperSetData}
              setCircuitsData={setCircuitsData}
            />
          </React.Fragment>
        ))
      }
    </Box>
  );
}

function WorkoutSectionsList (props) {
  const classes = useWorkoutSectionsListStyle();

  const {
    viewExerciseLink,
    exercisesBySection = {},
    workoutSections = [],
    selectedExerciseIndex,
    setSelectedExerciseIndex,
    viewSuperSetsLink,
    viewCircuitLink,
    setSuperSetData,
    setCircuitsData,
  } = props;

  return (
    <Box className={classes.root}>
      {
        workoutSections.map((section, index) => (
          <React.Fragment key={index}>
            <SectionDivider name={section.name} totalTime={section.totalTimeStr} />
            <SectionsContainer
              exercises={exercisesBySection[section.id] || []}
              viewExerciseLink={viewExerciseLink}
              selectedExerciseIndex={selectedExerciseIndex}
              setSelectedExerciseIndex={setSelectedExerciseIndex}
              viewSuperSetsLink={viewSuperSetsLink}
              viewCircuitLink={viewCircuitLink}
              setSuperSetData={setSuperSetData}
              setCircuitsData={setCircuitsData}
            />
          </React.Fragment>
        ))
      }
    </Box>
  );
}

const useWorkoutSectionsContainerStyles = makeStyles({
  workoutSectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
  },
});

function WorkoutSectionsContainer (props) {
  const {
    addExercisesKey,
    addExercisesListLink = () => {},
    editExerciseLink = () => {},
    copyWorkoutLink = () => {},
    viewExerciseLink,
    resetWorkoutIndex = () => {},
    backLink,
    workoutButtonsVisibilityFlags,
    updateButtonVisibilityFlags,
    sectionIds = [],
    isDragAndDropEnabled = true,
    selectedExerciseIndex,
    setSelectedExerciseIndex = () => {},

    // Props available when program context is in SCHEDULING
    onOpenScheduleDialog = () => {},
    isScheduleDialogOpen = false,
    refreshWorkouts = () => {},
    viewSuperSetsLink,
    viewCircuitLink,
    setSuperSetData,
    setCircuitsData,
  } = props;

  const { editable, workout, entities = {} } = useSelector(state => state.selectedWorkout);
  const { sections = {}, exercises = {} } = entities;
  const program = useSelector(state => state.selectedProgram.program);
  const currentUser = useSelector(state => state.currentUser.id);

  const classes = useWorkoutSectionsContainerStyles();

  const exercisesBySection = groupExercisesBySection(sectionIds, sections, exercises);

  // Calculate total exercise time per section
  const workoutSections = Object.values(sections).map(section => {
    return {
      ...section,
      totalTimeStr: getDurationForSection(exercisesBySection[section.id]),
    };
  });

  const location = useLocation();
  const query = queryString.parse(location.search);
  const currentView = query.view;
  const workoutId = query.workoutId;

  // Making sure that program exercises are only editable if on program view
  const isFromProgramFlow = location.pathname.includes('programs');
  const programIsEditable = program && (program.owner_id === currentUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (workoutId && workoutId !== 'new' && workout.id !== workoutId) {
      dispatch(selectWorkout({id: workoutId}));
    }
  }, [dispatch, workoutId, workout.id]);

  /* Swap between drag N' drop enabled and NON drag N' drop enabled list components */
  const SectionsListComponent = isDragAndDropEnabled
    ? (
      <DragNDropWorkoutSectionsList
        exercisesBySection={exercisesBySection}
        workout={workout}
        workoutSections={workoutSections}
        editExerciseLink={editExerciseLink}
        viewExerciseLink={viewExerciseLink}
        isDragDisabled={workoutButtonsVisibilityFlags.showCopyButton}
        isEditable={editable || (programIsEditable && isFromProgramFlow)}
        selectedExerciseIndex={selectedExerciseIndex}
        setSelectedExerciseIndex={setSelectedExerciseIndex}
        viewSuperSetsLink={viewSuperSetsLink}
        viewCircuitLink={viewCircuitLink}
        setSuperSetData={setSuperSetData}
        setCircuitsData={setCircuitsData}
      />
    )
    : (
      <WorkoutSectionsList
        exercisesBySection={exercisesBySection}
        viewExerciseLink={viewExerciseLink}
        workoutSections={workoutSections}
        selectedExerciseIndex={selectedExerciseIndex}
        setSelectedExerciseIndex={setSelectedExerciseIndex}
        viewSuperSetsLink={viewSuperSetsLink}
        viewCircuitLink={viewCircuitLink}
        setSuperSetData={setSuperSetData}
        setCircuitsData={setCircuitsData}
      />
    );

  return (
    <Box className={classes.workoutSectionsContainer}>
      <WorkoutInputContainer
        resetWorkoutIndex={resetWorkoutIndex}
        isEditable={editable || (programIsEditable && isFromProgramFlow && isDragAndDropEnabled)}
        workout={workout || {}}
        refreshWorkouts={refreshWorkouts}

        // Navigation
        addExercisesKey={addExercisesKey}
        addExercisesListLink={addExercisesListLink}
        copyWorkoutLink={copyWorkoutLink}
        backLink={backLink}

        // Button Visibility Flags
        currentView={currentView}
        workoutButtonsVisibilityFlags={workoutButtonsVisibilityFlags}
        updateButtonVisibilityFlags={updateButtonVisibilityFlags}

        onOpenScheduleDialog={onOpenScheduleDialog}
        isScheduleDialogOpen={isScheduleDialogOpen}
      />
      {SectionsListComponent}
    </Box>
  );
}

export default function WorkoutSectionsPanel (props) {
  const {
    visible,
    // Controls which kind of WorkoutSectionsList component to render internally
    // if true, this component will require to be wrapped in a DragDropContext component
    // and will allow exercises to be reordered and dragged into this Panel
    // if false, this component does not need to be wrapped in a DragDropContext component
    // and will NOT allow exercises to be reordered and dragged into this Panel
    isDragAndDropEnabled = true,
    ...rest
  } = props;

  return (
    <SlidingFlexTransition visible={visible}>
      <Panel height={800}>
        <FadeTransition visible={visible}>
          <WorkoutSectionsContainer
            isDragAndDropEnabled={isDragAndDropEnabled}
            {...rest}
          />
          <br />
        </FadeTransition>
      </Panel>
    </SlidingFlexTransition>
  );
}

function getDurationForSection (exercises = []) {
  let seconds = 0;
  let appendPlus = false;

  exercises.forEach((exercise) => {
    const exerciseSeconds = calculateSecondsForExercise(exercise);
    seconds += exerciseSeconds;
    if (exerciseSeconds === 0) {
      appendPlus = true;
    }
  });

  return getDurationFromSeconds(seconds, appendPlus);
}

function groupExercisesBySection (sectionIds, sections, exercises) {
  const exercisesBySection = {};
  for (const sectionId of sectionIds) {
    exercisesBySection[sectionId] = [];
    const exerciseKeys = sections[sectionId]?.exercises ?? [];
    for (const key of exerciseKeys) {
      if (exercises[key]) {
        exercisesBySection[sectionId].push(exercises[key]);
      }
    }
  }

  return exercisesBySection;
}
