import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ExerciseList from './ExerciseList';
import EmptySection from '../EmptyStates/EmptySection';
import ftuImgExercises from '../../resources/ftu-exercises.png';
import FilterMenu from '../Schedule/FilterMenu';
import NoSearchResults from '../EmptyStates/NoSearchResults';
import useFetchMuscleGroupsList from '../../hooks/FetchMuscleGroupsList';
import useFetchFitnessComponentsList from '../../hooks/FetchFitnessComponentsList';
import useFetchExerciseFilters from '../../hooks/exercises/FetchExerciseFilters';
import ExerciseListSkeleton from '../LoadingStates/ExerciseListSkeleton';
import nasmApi from '../../api/endpoints';
import BodyMuscleSelection from './BodyMuscleSelection';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClearOptions, HighlightSearchBar } from '../Inputs';
import { useExercisesContext } from '../../contexts/ExercisesContext';
import { usePaginationObserver, useDebounce } from '../../util/utilFunctions';
import { SortButton } from '../Buttons';

const ExerciseListWithRef = React.forwardRef((props, ref) => {
  return <ExerciseList lastExerciseRecordRef={ref} {...props} />;
});

const useStyles = makeStyles({
  boxPadding: {
    paddingRight: '20px',
  },
});

const EXERCISE_TABS = Object.freeze({
  TRAINER_EXERCISES: 'my-exercises',
  NASM_EXERCISES: 'nasm-exercises',
  FAV_EXERCISES: 'favorite-exercises',
});

const EXCLUDED_MUSCLES = [
  'arms',
  'legs',
  'upper body',
  'lower body',
  'total body',
];

function SearchBar (props) {
  const { clearSearchText, searchText, handleSearch } = props;
  const classes = useStyles();
  return (
    <Box className={classes.boxPadding}>
      <HighlightSearchBar
        isClearable={searchText.length > 0}
        onClickClear={clearSearchText}
        value={searchText}
        onChange={handleSearch}
      />
    </Box>
  );
}

function ExerciseListContent (props) {
  const {
    areSearchResultsNotFound,
    isInitialListLoading,
    isFirstTimeUserExp,
    exerciseData,
    superSets,
    currentTab, 
    lastExerciseRecordRef,
    isLoading,
    selectedIndex,
    setSelectedIndex,
    viewExerciseDetailsLink,
    viewSuperSetsLink,
    setSuperSetData,
    setSupersetPageNumber,
    hasMoreSuperset,
    isSuperSetLoading,
    viewCircuitLink,
    setCircuitsData,
  } = props;

  if (isFirstTimeUserExp) {
    return (
      <EmptySection
        ftuImg={ftuImgExercises}
        emptyTitle='Upload your own Exercises '
        emptyDescription='Make your workouts more personable by creating your own exercises.'
        ftuBtnHidden
      />
    );
  }

  if (isInitialListLoading) {
    return <ExerciseListSkeleton />;
  }

  if (areSearchResultsNotFound) {
    return <NoSearchResults />;
  }

  return (
    <ExerciseListWithRef
      exerciseData={exerciseData}
      superSets={superSets}
      currentTab={currentTab}
      lastExerciseRecordRef={lastExerciseRecordRef}
      isLoading={isLoading}
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      viewExerciseDetailsLink={viewExerciseDetailsLink}
      viewSuperSetsLink={viewSuperSetsLink}
      setSuperSetData={setSuperSetData}
      setSupersetPageNumber={setSupersetPageNumber}
      hasMoreSuperset={hasMoreSuperset}
      isSuperSetLoading={isSuperSetLoading}
      viewCircuitLink={viewCircuitLink}
      setCircuitsData={setCircuitsData}
    />
  );
}

const useSearchAndFiltersStyle = makeStyles({
  boxContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '20px',
    height: '10%',
  },
});

/**
 * @return {JSX|null}
 */
function SearchAndFilters (props)  {
  const {
    visible,
    clearSearchText,
    searchText,
    handleSearch,
    myExercises,
    exerciseSources,
    muscleGroups,
    fitnessComponents,
    pendingSourceFilters,
    pendingMuscleGroupFilters,
    pendingFitnessComponentFilters,
    handleFilters,
    setPendingSourceFilters,
    setPendingMuscleGroupFilters,
    setPendingFitnessComponentFilters,
    clearAllFilters,
    activeFilterCount,
    onCloseFilter,
    isSortActive,
    onToggleSort,
    isClearOptionsVisible,
    onClearOptions,
  } = props;

  const classes = useSearchAndFiltersStyle();

  if (!visible) {
    return null;
  }

  return (
    <Box className={classes.boxContainer}>
      <SearchBar
        clearSearchText={clearSearchText}
        searchText={searchText}
        handleSearch={handleSearch}
      />
      <FilterMenu
        sources={myExercises ? [] : exerciseSources}
        muscleGroups={muscleGroups}
        fitnessComponents={fitnessComponents}
        // Current active filters
        sourceFilters={pendingSourceFilters}
        muscleGroupFilters={pendingMuscleGroupFilters}
        fitnessComponentFilters={pendingFitnessComponentFilters}
        handleSourceFilters={source => handleFilters(source, setPendingSourceFilters)}
        handleMuscleGroupFilters={muscle => handleFilters(muscle, setPendingMuscleGroupFilters)}
        handleFitnessComponentFilters={component => handleFilters(component, setPendingFitnessComponentFilters)}
        clearAllFilters={clearAllFilters}
        activeFilterCount={activeFilterCount}
        onClose={onCloseFilter}
      />
      <SortButton
        text='Most Recent'
        margin='0 0 0 12px'
        isActive={isSortActive}
        onClick={onToggleSort}
      />
      <ClearOptions
        text='Clear All'
        visible={isClearOptionsVisible}
        margin='0 0 0 12px'
        onClick={onClearOptions}
      />
    </Box>
  );
}

function ExercisesScreen (props) {
  const {
    selectedIndex,
    setSelectedIndex,
    resetSelection,
    viewExerciseDetailsLink,
    viewSuperSetsLink,
    setSuperSetData,
    viewCircuitLink,
    setCircuitsData,
  } = props;


  const [searchText, setSearchText] = useState('');
  const [pageNumber, setPageNumber] = useState(1);

  // Delay search until user is done typing in search bar
  const debouncedSearch = useDebounce(searchText, 500);

  const location = useLocation();
  const currentTab = location.pathname.split('/').pop();

  const { exerciseFilters } = useFetchExerciseFilters();
  const { muscleGroups: bodyRegionFilters } = useFetchMuscleGroupsList();
  const muscleGroups = [{ id: null, name: 'Bodymap' }, ...bodyRegionFilters];
  const { fitnessComponents } = useFetchFitnessComponentsList();
  const exerciseSources = exerciseFilters.filter(src => !['nasm', 'trainer'].includes(src.filter_value));
  const [sourceFilters, setSourceFilters] = useState([]);
  const [muscleGroupFilters, setMuscleGroupFilters] = useState([]);
  const [fitnessComponentFilters, setFitnessComponentFilters] = useState([]);

  const [pendingSourceFilters, setPendingSourceFilters] = useState([]);
  const [pendingMuscleGroupFilters, setPendingMuscleGroupFilters] = useState([]);
  const [pendingFitnessComponentFilters, setPendingFitnessComponentFilters] = useState([]);

  const [isSortActive, setSortActive] = useState(false);
  const [isClearOptionsVisible, setClearOptionsVisible] = useState(false);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  const [isFirstTimeUserExp, setFirstTimeUserExp] = useState(false);

  const tempMuscleGrpIds = muscleGroupFilters.join(',');
  const tempFitnessComponentIds = fitnessComponentFilters.join(',');

  const [showBodyMap, setShowBodyMap] = useState(false);
  const [selectedMuscles, setSelectedMuscles] = useState([]);
  const [supersetPageNumber, setSupersetPageNumber] = useState(1);
  const [hasMoreSuperset, setHasMoreSuperset] = useState(true);
  const [isSuperSetLoading, setIsSuperSetLoading] = useState(false);
  const [compoundRoutines, setCompoundRoutines] = useState([]);
  
  useEffect(() => {
    fetchAllSuperSet();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, currentTab, supersetPageNumber]);

  const fetchAllSuperSet = async () => {
    if (currentTab === EXERCISE_TABS.NASM_EXERCISES) {
      setSupersetPageNumber(1);
      setHasMoreSuperset(true);
      setCompoundRoutines([]);
    } else {
        const jsonData = {
          page: supersetPageNumber,
          size: 20,
          search: debouncedSearch,
        };
        if(hasMoreSuperset) {
          setIsSuperSetLoading(true);
          try {
            const res = await nasmApi.supersets.fetchAllSuperSets(jsonData);
            if (res) {
                setHasMoreSuperset(res?.result?.hasMore);
                setCompoundRoutines((prevState) => ( jsonData.page === 1
                  ? [...res?.result?.compoundRoutines]
                  : [...prevState, ...res?.result?.compoundRoutines]));
                setIsSuperSetLoading(false);
            }
          } catch (error) {
            alert(error?.message || 'Something went wrong! Please try again later.');
          }
        }
    }
  };

  let activeExerciseFilter = '';
  if (currentTab === EXERCISE_TABS.NASM_EXERCISES) {
    // exclude nasm since nasm includes all sources
    if (sourceFilters.length > 0) {
      activeExerciseFilter = sourceFilters.join(',');
    } else {
      activeExerciseFilter = 'nasm';
    }
  } else if (currentTab === EXERCISE_TABS.FAV_EXERCISES) {
    activeExerciseFilter = 'favorites';
    if (sourceFilters.length > 0) {
      activeExerciseFilter = `${sourceFilters.toString()},favorites`;
    }
  } else {
    activeExerciseFilter = 'trainer';
  }

  const {
    fetchExercises,
    clearExercises,
    exercises: exerciseData,
    loading,
    hasMore,
    pageNumber: newPageNumber,
  } = useExercisesContext();

  // Reset Search and Filters when Tab changes
  useEffect(() => {
    clearAllFilters();
    setSearchText('');
    setSortActive(false);
  }, [currentTab]);

  useEffect(() => {
    setPageNumber(newPageNumber);
  }, [newPageNumber]);

  // Reset exercise list when any
  // search/filtering/sorting fields change
  useEffect(() => {
    clearExercises();
  }, [
    clearExercises,
    debouncedSearch,
    activeExerciseFilter,
    tempMuscleGrpIds,
    tempFitnessComponentIds,
    sortField,
    sortOrder,
  ]);

  useEffect(() => {
    setPageNumber(1);
    if(isSortActive) {
      setSortField('created_at');
      setSortOrder('desc');
    } else {
      setSortField('');
      setSortOrder('');
    }
  }, [isSortActive]);

  // Used to determine whether or not FTU Panel should be shown
  useEffect(() => {
    const cancel = axios.CancelToken.source();
    if (currentTab === EXERCISE_TABS.TRAINER_EXERCISES) {
      const searchParams = {
        page: 1,
        size: 1,
        exercisesFilter: 'trainer',
      };

      nasmApi.nasmExercises.findExercises(searchParams, cancel.token)
        .then(data => {
          if(!data.isRequestCancelled) {
            if(!!data.result) {
              setFirstTimeUserExp(data.result.exercises.length === 0 &&
                currentTab === EXERCISE_TABS.TRAINER_EXERCISES);
            } else {
              setFirstTimeUserExp(false);
            }
          }
        });
    } else {
      setFirstTimeUserExp(false);
    }

    return () => cancel.cancel('Exercise Search Request FTU Cancelled');
  }, [currentTab]);

  const rootElement = useRef();
  const lastExerciseRecordRef = usePaginationObserver({
    setPageNumber: setPageNumber,
    isLoading: loading,
    hasMoreDataToLoad: hasMore,
    rootElement: rootElement.current,
  });

  useEffect(() => {
    if (currentTab === EXERCISE_TABS.NASM_EXERCISES 
      || (!hasMoreSuperset || debouncedSearch.length > 0)) {
      fetchExercises({
        pageNumber,
        searchText: debouncedSearch,
        sizePerPage: 20,
        // Set the filter for the current source tab plus any selected filters.
        activeExerciseFilter,
        muscleGroupIds: tempMuscleGrpIds,
        fitnessComponentIds: tempFitnessComponentIds,
        sortField: sortField,
        sortOrder: sortOrder,
      });
    } 
  }, [
    currentTab,
    hasMoreSuperset,
    fetchExercises,
    pageNumber,
    debouncedSearch,
    activeExerciseFilter,
    tempMuscleGrpIds,
    tempFitnessComponentIds,
    sortField,
    sortOrder,
  ]);


  const updateMuscles = useCallback(() => {
    const musclesCopy = [];
    pendingMuscleGroupFilters.forEach((filterId) => {
      const filter = bodyRegionFilters.find((fltr) => fltr.id === filterId);
      if (filter && !EXCLUDED_MUSCLES.includes(filter.name?.toLowerCase())) {
        musclesCopy.push({ muscles: [filter.name?.toLowerCase().replace(' ', '')], frequency: 1 });
      }
    });
    setSelectedMuscles(musclesCopy);
  }, [pendingMuscleGroupFilters, setSelectedMuscles, bodyRegionFilters]);

  useEffect(() => {
    updateMuscles();
  }, [updateMuscles]);

  function handleSearch (e) {
    setSearchText(e.target.value);
    setPageNumber(1);
    resetSelection();
    setSupersetPageNumber(1);
    setHasMoreSuperset(true);
    setCompoundRoutines([]);
  }

  const handleFilters = (id, updateActiveFilters) => {
    if (id) {
      updateActiveFilters(prevState => {
        const filtersCopy = [...prevState];
        const idIndex = filtersCopy.indexOf(id);
  
        // remove from filters array if id exists
        if (idIndex !== -1) {
          filtersCopy.splice(idIndex, 1);
        } else {
          // add id to filters array
          filtersCopy.push(id);
        }
        return [...(new Set([...filtersCopy]))];
      });
    } else {
      setShowBodyMap(!showBodyMap);
      updateMuscles();
    }
  };

  const clearAllFilters = () => {
    setSourceFilters([]);
    setMuscleGroupFilters([]);
    setFitnessComponentFilters([]);
    setPendingSourceFilters([]);
    setPendingMuscleGroupFilters([]);
    setPendingFitnessComponentFilters([]);
    setPageNumber(1);
  };

  const clearSearchText = () => {
    setSearchText('');
    setPageNumber(1);
    setSupersetPageNumber(1);
    setHasMoreSuperset(true);
    setCompoundRoutines([]);
  };

  const onCloseFilter = () => {
    setSourceFilters(pendingSourceFilters);
    setFitnessComponentFilters(pendingFitnessComponentFilters);
    setMuscleGroupFilters(pendingMuscleGroupFilters);
    setPageNumber(1);
    setClearOptionsVisible(true);
  };

  const onClearOptions = () => {
    setSortActive(false);
    setClearOptionsVisible(false);

    clearAllFilters();
  };

  const onToggleSort = () => {
    setSortActive(prev => {
      if(prev && !fitnessComponentFilters.length && !muscleGroupFilters.length && !sourceFilters.length) {
        setClearOptionsVisible(false);
      } else {
        setClearOptionsVisible(true);
      }

      return !prev;
    });
  };

  const activeFilterCount = sourceFilters.length + muscleGroupFilters.length + fitnessComponentFilters.length;

  const areSearchResultsNotFound =
    !loading &&
    exerciseData.length === 0 && compoundRoutines.length === 0 &&
    (searchText !== '' || activeFilterCount >= 1);
  const isInitialListLoading = isSuperSetLoading && compoundRoutines.length === 0;

  return (
    <Box ref={rootElement} style={{ height: 'inherit' }}>
      <SearchAndFilters
        visible={!isFirstTimeUserExp}
        clearSearchText={clearSearchText}
        searchText={searchText}
        handleSearch={handleSearch}
        myExercises={location.state?.myExercises }
        exerciseSources={exerciseSources}
        muscleGroups={muscleGroups}
        fitnessComponents={fitnessComponents}
        pendingSourceFilters={pendingSourceFilters}
        pendingMuscleGroupFilters={pendingMuscleGroupFilters}
        pendingFitnessComponentFilters={pendingFitnessComponentFilters}
        handleFilters={handleFilters}
        setPendingSourceFilters={setPendingSourceFilters}
        setPendingMuscleGroupFilters={setPendingMuscleGroupFilters}
        setPendingFitnessComponentFilters={setPendingFitnessComponentFilters}
        clearAllFilters={clearAllFilters}
        activeFilterCount={activeFilterCount}
        onCloseFilter={onCloseFilter}
        isSortActive={isSortActive}
        onToggleSort={onToggleSort}
        isClearOptionsVisible={isClearOptionsVisible}
        onClearOptions={onClearOptions}
      />
      <ExerciseListContent
        areSearchResultsNotFound={areSearchResultsNotFound}
        isInitialListLoading={isInitialListLoading}
        isFirstTimeUserExp={isFirstTimeUserExp}
        exerciseData={exerciseData}
        superSets={compoundRoutines}
        currentTab={currentTab}
        lastExerciseRecordRef={lastExerciseRecordRef}
        isLoading={loading}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        viewExerciseDetailsLink={viewExerciseDetailsLink}
        viewSuperSetsLink={viewSuperSetsLink}
        setSuperSetData={setSuperSetData}
        setSupersetPageNumber={setSupersetPageNumber}
        hasMoreSuperset={hasMoreSuperset}
        isSuperSetLoading={isSuperSetLoading}
        viewCircuitLink={viewCircuitLink}
        setCircuitsData={setCircuitsData}
      />
      {showBodyMap ? (
        <BodyMuscleSelection
          bodyRegionFilters={bodyRegionFilters}
          selectedMuscles={selectedMuscles}
          setSelectedMuscles={setSelectedMuscles}
          showBodyMap={showBodyMap}
          setShowBodyMap={setShowBodyMap}
          setPendingMuscleGroupFilters={setPendingMuscleGroupFilters}
        />
      ) : null}
    </Box>
  );
}

ExercisesScreen.propTypes = {
  selectedIndex: PropTypes.number.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  resetSelection: PropTypes.func.isRequired,
  viewExerciseDetailsLink: PropTypes.func.isRequired,
};

export default ExercisesScreen;
