import LocalStore from '../nasm_sdk_v2/local_store';
import { STORAGE_KEYS, API_VERSION } from '../nasm_sdk_v2/constants';

import oauth from './oauth';
import register from './register';
import users from './users';
import trainer from './trainer';
import client from './client';
import goals from './goals';
import nasmExercises from './nasm_exercises';
import nasmPrograms from './nasm_programs';
import nasmWorkouts from './nasm_workouts';
import customTrainerExercises from './custom_trainer_exercises';
import workoutSections from './workout_sections';
import fitnessComponents from './fitness_components';
import muscleGroups from './muscle_groups';
import workoutSchedule from './workout_schedule';
import exerciseImageUpload from './image_upload';
import exerciseVideoUpload from './media_upload';
import terms from './terms_conditions_privacy';
import dashboard from './dashboard';
import passwordReset from './passwordReset';
import oneRm from './oneRm';
import bmi from './bmi';
import bwp from './bwp';
import mhr from './mhr';
import bodyFat from './bodyFat';
import supersets from './supersets';

const { AUTH_TOKEN, LOGIN_PREFERENCES } = STORAGE_KEYS;

function nasmApi(apiVersion, store) {
  // Storage Retrieval, Setting, and Deleting Functions //

  function getTokens() {
    return store.get(AUTH_TOKEN);
  }

  function hasTokens() {
    return store.hasKey(AUTH_TOKEN);
  }

  function removeAuthTokens() {
    store.delete(AUTH_TOKEN);
    return !store.hasKey(AUTH_TOKEN);
  }

  function updateLoginPreferences(rememberMe, email) {
    let preferences = [];
    if (rememberMe && email) {
      preferences = { rememberMe, email };
    }

    store.save(LOGIN_PREFERENCES, preferences);
  }

  function getLoginPreferences() {
    return store.get(LOGIN_PREFERENCES);
  }

  return {
    oauth: oauth(apiVersion, store),
    register: register(apiVersion),
    users: users(apiVersion),
    trainer: trainer(apiVersion),
    client: client(apiVersion),
    goals: goals(apiVersion),
    nasmExercises: nasmExercises(apiVersion),
    nasmPrograms: nasmPrograms(apiVersion),
    nasmWorkouts: nasmWorkouts(apiVersion),
    customTrainerExercises: customTrainerExercises(apiVersion),
    workoutSections: workoutSections(apiVersion),
    fitnessComponents: fitnessComponents(apiVersion),
    muscleGroups: muscleGroups(apiVersion),
    workoutSchedule: workoutSchedule(apiVersion),
    imageUpload: exerciseImageUpload(apiVersion),
    mediaUpload: exerciseVideoUpload(apiVersion),
    terms: terms(apiVersion),
    dashboard: dashboard(apiVersion),
    passwordReset: passwordReset(apiVersion),
    oneRm: oneRm(apiVersion),
    bmi: bmi(apiVersion),
    bwp: bwp(apiVersion),
    mhr: mhr(apiVersion),
    bodyFat: bodyFat(apiVersion),
    supersets: supersets(apiVersion),
    getTokens,
    hasTokens,
    removeAuthTokens,
    updateLoginPreferences,
    getLoginPreferences,

  };
}

export default nasmApi(API_VERSION, LocalStore);