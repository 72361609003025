import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FilterButton } from '../Buttons';
import { SquareCheckbox } from '../Inputs';

import { colors } from '../../styles';
import { ArrowForwardIos } from '@material-ui/icons';

const useStyles = makeStyles({
  filterMenuRoot: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  marginSpacing: {
    marginTop: 10,
  },
  paperRoot: {
    width: 265,
  },
  menu: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  popoverPaper: {
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.22)',
    borderRadius: '6px',
    border: `solid 1px ${colors.selected_highlight_copy_6}`,
    overflowY: 'scroll',
  },
  clearFilters: {
    marginLeft: 13,
    cursor: 'pointer',
    color: colors.steel,
    fontSize: 14,
  },
});

function FilterMenu (props) {
  const {
    sources = [],
    muscleGroups = [],
    fitnessComponents = [],
    sourceFilters = [],
    muscleGroupFilters = [],
    fitnessComponentFilters = [],
    activeFilterCount = 0,
    handleSourceFilters,
    handleMuscleGroupFilters,
    handleFitnessComponentFilters,
    // clearAllFilters,
    onClose,
  } = props;

  const classes = useStyles();

  const [isPressed, setPressed] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenFilterMenu = e => {
    setPressed(true);
    setAnchorEl(e.target);
  };

  const onCloseFilterMenu = () => {
    setPressed(false);
    setAnchorEl(null);
    onClose();
  };

  return (
    <Box className={classes.filterMenuRoot}>
      <FilterButton
        width={74}
        isPressed={isPressed}
        filterCount={activeFilterCount}
        onOpenFilterMenu={onOpenFilterMenu}
      />
      {/* TODO: move this out and place in components that need this functionality for Sorting*/}
      {/*<ClearOptions*/}
      {/*  text='Clear All'*/}
      {/*  onClick={clearAllFilters}*/}
      {/*  visible={activeFilterCount > 0}*/}
      {/*/>*/}
      <Menu
        className={classes.marginSpacing}
        classes={{ paper: classes.paperRoot }}
        keepMounted
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onCloseFilterMenu}
        // Set to null so that anchorOrigin and transformOrigin
        // can be set without warnings/errors
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          className: classes.menu,
        }}
        PopoverClasses={{ paper: classes.popoverPaper }}
      >
        {sources.length > 0 && <FilterHeader label='Source' />}
        {sources.map(source => (
          <FilterItem
            key={source.filter_value}
            id={source.filter_value}
            label={source.display_value}
            isSelected={sourceFilters.includes(source.filter_value)}
            handleFilterFlags={handleSourceFilters}
          />),
        )}
        {muscleGroups.length > 0 && <FilterHeader label='Body Region' />}
        {muscleGroups.map(muscleGroup => (
          <FilterItem
            key={muscleGroup.id}
            id={muscleGroup.id}
            label={muscleGroup.name}
            isSelected={muscleGroupFilters.includes(muscleGroup.id)}
            handleFilterFlags={handleMuscleGroupFilters}
          />),
        )}
        {fitnessComponents.length > 0 && <FilterHeader label='Fitness Component' />}
        {fitnessComponents.map(fitnessComponent => (
          <FilterItem
            key={fitnessComponent.id}
            id={fitnessComponent.id}
            label={fitnessComponent.name}
            isSelected={fitnessComponentFilters.includes(fitnessComponent.id)}
            handleFilterFlags={handleFitnessComponentFilters}
          />),
        )}
      </Menu>
    </Box>
  );
}

const useFilterHeaderStyle = makeStyles({
  header: {
    padding: '20px 7px 19px 18px',
    backgroundColor: 'rgba(240, 241, 243, 0.41)',
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 'normal',
  },
});

// Must forwardRef to get rid of forwardRef warning in the browser console
// Only happens if FilterHeader is the first Component inside of a Menu Component
const FilterHeader = React.forwardRef((props, ref) => {
  const classes = useFilterHeaderStyle();
  return (
    <Box className={classes.header}>
      <Typography className={classes.headerTitle}>
        {props.label}
      </Typography>
    </Box>
  );
});

const useFilterItemStyle = makeStyles({
  menuItem: (props) => ({
    display: 'flex',
    justifyContent: props.id ? 'flex-start' : 'space-between',
    paddingLeft: 10,
  }),
  menuText: {
    fontSize: 17,
    fontWeight: 500,
    lineHeight: 2.29,
  },
  arrowIcon: {
    width: 20,
    height: 20,
    color: colors.dark_grey,
  },
});

function FilterItem (props) {
  const { label, id, isSelected, handleFilterFlags } = props;
  const classes = useFilterItemStyle({ id });
  return (
    <MenuItem
      className={classes.menuItem}
      onClick={() => handleFilterFlags(id)}
    >
      {id ? <SquareCheckbox isChecked={isSelected} /> : null}
      <Typography className={classes.menuText}>
        {label}
      </Typography>
      {!id ? <ArrowForwardIos className={classes.arrowIcon} /> : null}
    </MenuItem>
  );
}

FilterMenu.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.object),
  muscleGroups: PropTypes.arrayOf(PropTypes.object),
  fitnessComponents: PropTypes.arrayOf(PropTypes.object),
  sourceFilters: PropTypes.arrayOf(PropTypes.string),
  muscleGroupFilters: PropTypes.arrayOf(PropTypes.string),
  fitnessComponentFilters: PropTypes.arrayOf(PropTypes.string),
  activeFilterCount: PropTypes.number,
  handleSourceFilters: PropTypes.func,
  handleMuscleGroupFilters: PropTypes.func,
  handleFitnessComponentFilters: PropTypes.func,
  clearAllFilters: PropTypes.func,
  onClose: PropTypes.func,
};

export default FilterMenu;
