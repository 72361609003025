import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { colors } from '../styles';
import background2x from '../resources/general-background@2x.jpg';
import { ReactComponent as MaintenanceIcon } from '../resources/icon-maintenance.svg';

const Container = styled('div')({
  backgroundImage: `url(${background2x})`,
  backgroundSize: 'cover',
  backgroundPositionX: 'center',
  height: 'auto',
  minHeight: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'center',
});

const BlueBoxContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(22, 57, 99, 0.75)',
  width: '500px',
  height: '510px',
  borderRadius: '19px',
  margin: 'auto',
});

const TextContainer = styled('div')({
  margin: '36px',
});

const Title = styled(Typography)({
  fontSize: '40px',
  fontWeight: 'bold',
  textAlign: 'center',
  color: colors.white,
  margin: '24px 0 12px 0',
});

const SubTitle = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: '19px',
  textAlign: 'center',
  color: colors.white,
});

/* eslint-disable max-len */
function MaintenancePage () {
  return (
    <Container>
      <BlueBoxContainer>
        <MaintenanceIcon />
        <TextContainer>
          <Title>Planned Maintenance</Title>
          <SubTitle>
            NASM EDGE is unavailable from {process.env.REACT_APP_MAINTENANCE_TIME} on {process.env.REACT_APP_MAINTENANCE_DATE} due to maintenance.
            Thank you for your patience and support.
          </SubTitle>
        </TextContainer>
      </BlueBoxContainer>
    </Container>
  );
}

export default MaintenancePage;
