import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, TextField, Menu, MenuItem } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { colors } from '../../styles';
import dropdownArrow from '../../resources/btn-arrow-expand.svg';
import { MacaroniButton, NavyButton } from '../Buttons';
import { updateQuery } from '../../util/utilFunctions';
import { saveProgram } from '../../reducers/selectedProgramReducer';

/*
  PROGRAM NAME - Either text field or un-editable typography
*/

const useProgramFieldStyles = makeStyles({
  programTitle: {
    fontSize: '17px',
    marginBottom: '10px',
    fontFamily: 'Avenir-Bold, sans-serif',
    fontWeight: 'bold',
  },
  programNameDivider: {
    borderBottom: '1px solid #e6e6e6',
  },
  programNameError: {
    color: colors.red,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
  },
});

export const ProgramNameField = (props) => {
  const {
    isMyPrograms,
    isScheduling,
    isDuplicate,
    isRescheduling,
    onChange,
    onBlurProgramName,
    program,
    programChanged,
  } = props;

  const classes = useProgramFieldStyles();
  const programName = program && program.name ? program.name : '';

  // Program name is not editable
  if ((!isMyPrograms || isScheduling) && !isDuplicate && !isRescheduling) {
    return (
      <Box>
        <Typography className={classes.programTitle}>{programName}</Typography>
        <Box className={classes.programNameDivider} />
      </Box>
    );
  }
  // Program name is editable
  if ((isMyPrograms || isDuplicate || isRescheduling) && !isScheduling) {
    return (
      <Box>
        <TextField
          required
          value={programName}
          placeholder='Program Name'
          className={classes.programTitle}
          fullWidth
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlurProgramName}
        />
        {
          programName === '' && programChanged
            ? <Typography className={classes.programNameError}>Program name is required.</Typography>
            : null
        }
      </Box>
    );
  }
  return null;
};

/*
  PROGRAM CATEGORY - Either menu dropdown or un-editable typography
*/

const useProgramCategoryDropdownStyles = makeStyles({
  dropdownCont: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '290px',
    height: '40px',
    border: '1px solid #d2d7db',
    borderRadius: '3px',
    cursor: 'pointer',
    padding: '0 10px',
  },
  dropDownRight: {
    display: 'flex',
    alignItems: 'center',
  },
  dropdownItems: {
    width: '320px',
  },
  selectedCategory: {
    marginRight: '5px',
    color: colors.steel,
    fontSize: '14px',
  },
  menuItem: {
    width: '290px',
  },
});

export const ProgramCategoryDropdown = (props) => {
  const {
    isMyPrograms,
    isScheduling,
    isRescheduling,
    programCategories,
    anchorEl,
    onClickDropdownMenu,
    onClickDropdownItem,
    onCloseDropdownMenu,
    program,
  } = props;

  const classes = useProgramCategoryDropdownStyles();

  if ((isMyPrograms && !isScheduling) && !isRescheduling) {
    return (
      <>
        <Box className={classes.dropdownCont} onClick={onClickDropdownMenu}>
          <Typography>Category</Typography>
          <Box className={classes.dropDownRight}>
            <Typography className={classes.selectedCategory}>
              {program && program.program_category ? program.program_category.label : 'Select Category'}
            </Typography>
            <img
              src={dropdownArrow}
              alt='dropdown-arrow'
            />
          </Box>
        </Box>
        <Menu
          id='program-category-dropdown'
          className={classes.dropdownItems}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onCloseDropdownMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {
            Object.values(programCategories).map((category, index) => {
              return (
                <MenuItem
                  className={classes.menuItem}
                  key={index}
                  onClick={() => onClickDropdownItem(category.label)}
                >
                  {category.label}
                </MenuItem>
              );
            })
          }
        </Menu>
      </>
    );
  }
  return null;
};

const useProgramCategoryTextStyles = makeStyles({
  fixedProgramCategoryCont: {
    display: 'flex',
    alignItems: 'center',
    width: '300px',
    margin: '10px 20px',
  },
  categoryHeader: {
    marginRight: '5px',
    fontWeight: 'bold',
  },
});

export const ProgramCategoryText = (props) => {
  const {
    isMyPrograms,
    isScheduling,
    isRescheduling,
    program,
  } = props;

  const classes = useProgramCategoryTextStyles();

  if (!isMyPrograms || isScheduling || isRescheduling) {
    return (
      <Box className={classes.fixedProgramCategoryCont}>
        <Typography className={classes.categoryHeader}>Category: </Typography>
        <Typography>{program && (program.program_category?.label ?? '')}</Typography>
      </Box>
    );
  }
  return null;
};

/*
  ADD WORKOUTS BUTTON
*/

export const AddWorkoutButton = (props) => {
  const {
    isMyPrograms,
    isScheduling,
    isRescheduling,
    currentView,
    program,
    addWorkoutKey,
    fillProgramInfo,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const getProgramId = () => {
    if (isRescheduling) {
      // eslint-disable-next-line camelcase
      return program && program.nasm_program_id ? program.nasm_program_id : 'new';
    } else {
      return program && program.id ? program.id : 'new';
    }
  };

  const handleOnClick = () => {
    fillProgramInfo();
    dispatch(saveProgram());
    history.push({
      search: updateQuery(location.search,
        {
          view: addWorkoutKey,
          programId: getProgramId(),
        }),
    });
  };

  if ((isMyPrograms && !isScheduling) || isRescheduling) {
    const button = currentView === 'programDetails'
      ? <MacaroniButton width='144px' onClick={handleOnClick}>Add Workouts</MacaroniButton>
      : <NavyButton width='144px'>Add Workouts</NavyButton>;
    return (
      <Box>
        {button}
      </Box>
    );
  }

  return null;
};
