import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Local
import { colors } from '../../styles';
import arrowWhite from '../../resources/arrow-white.svg';

const useStyles = makeStyles({
  menuRoot: props => ({
    background: props.selectedProfileClubId ? colors.charcoal_grey : colors.lightBlue,
    borderRadius: 6,
    margin: 0,
    paddingTop: 2,
    paddingBottom: 2,
    width: 137,
    '&:hover': {
      backgroundColor: props.selectedProfileClubId ? colors.carbon_black : colors.perfAssessHeader,
    },
  }),
  menuLabel: {
    textTransform: 'capitalize',
    fontSize: 19,
    fontWeight: '900',
    fontStyle: 'normal',
  },
  menuItemLabel: {
    textTransform: 'capitalize',
  },
  menuLink: {
    textDecoration: 'none',
  },
  menuText: {
    fontFamily: 'DMSans',
    fontSize: 17,
    fontWeight: 500,
    lineHeight: 2.29,
    textTransform: 'capitalize',
    color: colors.black,
    paddingLeft: 20,
    paddingRight: 10,
    width: 117,
    '&:hover': {
      backgroundColor: colors.selected_highlight_copy_6,
    },
  },
  menuContainer: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  popoverPaper: {
    boxShadow: '0 3px 2px 0 rgba(0, 0, 0, 0.38)',
    borderRadius: '6px',
    objectFit: 'contain',
    border: `solid 1px ${colors.selected_highlight_copy_6}`,
  },
  menuPopover: {
    // used to shift menu down
    marginTop: '5px',
  },
});

export default function NavMenuPrimary ({ routes, activeRoute }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedProfileClubId = useSelector(state => state?.clubConnect?.selectedProfile?.ClubId);
  const classes = useStyles({selectedProfileClubId});


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls='nav-menu'
        aria-haspopup='true'
        variant='contained'
        color='primary'
        classes={{
          root: classes.menuRoot,
          label: classes.menuLabel,
        }}
        onClick={handleClick}
      >
        {activeRoute} <img src={arrowWhite} alt='down arrow' />
      </Button>
      <Menu
        id='nav-menu'
        className={classes.menuPopover}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        MenuListProps={{
          className: classes.menuContainer,
        }}
        PopoverClasses={{
          paper: classes.popoverPaper,
        }}
      >
        {
          routes.map(route => (
            <Link key={route} className={classes.menuLink} to={`/${route}`}>
              <MenuItem className={classes.menuText} onClick={handleClose}>
                {route}
              </MenuItem>
            </Link>
          ))
        }
      </Menu>
    </>
  );
}
