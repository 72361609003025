import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {  Box, ButtonBase } from '@material-ui/core';

import PlusButtonBlue from '../../resources/create-new-active.svg';
import PlusButtonOrange from '../../resources/button-create-new.svg';

const useStyles = makeStyles({
  floatingButton: {
    position: 'absolute',
    top: '170px',
    right: '150px',
    width: '68px',
    height: '68px',
    borderRadius: '34px',
    '&:hover': {
      filter: 'brightness(94%)',
    },
    '&:disabled': {
      filter: 'brightness(100%)',
    },
    // https://stackoverflow.com/questions/1417934/how-to-prevent-scrollbar-from-repositioning-web-page
    // prevent scrollbar from moving this button to the left or right
    paddingLeft: 'calc(100vw - 100%)',
  },
  buttonBase: {
    width: '68px',
    height: '68px',
    borderRadius: '34px',
  },
});

// Goal: remove useHistory dependency
function FloatingCircleButton (props) {
  const { onClick, disabled } = props;
  const classes = useStyles();

  return (
    <Box className={classes.floatingButton}>
      <ButtonBase className={classes.buttonBase} disabled={disabled} onClick={onClick}>
        <img
          src={disabled ? PlusButtonBlue : PlusButtonOrange}
          alt='Add'
        />
      </ButtonBase>
    </Box>
  );
}

FloatingCircleButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FloatingCircleButton;