import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['currentUser', 'selectedClient', 'selectedGroup', 'selectedWorkout', 'clubConnect'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureAppStore () {
  const store = configureStore({
    reducer: persistedReducer,
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  const persistor = persistStore(store);

  return { store, persistor };
}
