/**
 * @author Donald Green <donald.green@medlmobile.com>
 */
const buildEnv = 'debug';

export const API_VERSION = 'v2.1';

export const NASM_API_ENDPOINTS = {
  OAUTH: '/oauth/token',
  USERS: '/api/users',
  PROGRAMS: '/api/programs',
  REGISTER: '/api/register',
  EXERCISE: '/api/exercise',
  PASS_RESET: '/api/password-reset',
  CERTIFICATES: '/api/certificates',
  CREDENTIALS: '/api/credentials',
  GOALS: '/api/goals',
  ACTIONS: '/actions',
  PURCHASES: '/api/purchases',
  NASM_PROGRAMS: '/api/nasm-programs',
  NASM_WORKOUTS: '/api/nasm-workouts',
  NASM_EXERCISES: '/api/nasm-exercises',
  NASM_ASSESSMENTS: '/api/nasm-assessments',
  PROGRAM_CATEGORIES: '/api/program-categories',
  FITNESS_COMPONENTS: '/api/fitness-components',
  MUSCLE_GROUPS: '/api/muscle-groups',
  WORKOUT_SECTIONS: '/api/workout-sections',
  MILESTONES: 'milestones',
  ACTIVITY_LEVELS: '/api/activity-levels',
  NUTRITION: 'nutrition',
  MEASUREMENTS: 'measurements',
  GRAPH_DATA: 'measurements/graph-data',
  CLIENT_PREF: 'client-pref',
  DASHBOARD: 'dashboard',
  EXAM_PREP: '/api/exam-prep',
  CLIENT_GROUP: '/api/client-group',
};

export const NASM_OAUTH_GRANT_TYPES = {
  PASSWORD: 'password',
  REFRESH_TOKEN: 'refresh_token',
};

export const MAX_RETRY_COUNT = 2;

let oauth = {};
switch (buildEnv) {
  case 'debug':
    oauth = {
      ID: 'client',
      SECRET: 'secret',
    };
    break;
  default:
    oauth = {
      ID: 'client',
      SECRET: 'secret',
    };
    break;
}

export const NASM_OAUTH_CLIENT = oauth;
