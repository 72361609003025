import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Dialog, Typography, RadioGroup } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import nasmApi from '../../api/endpoints';
import { getMe } from '../../reducers/currentUserReducer';
import { SAVE_STATES } from '../../constants';
import SaveButton from '../Buttons/SaveButton';
import OvalButton from '../Buttons/OvalButton';
import RadioButton from '../Buttons/RadioButton';
import { colors } from '../../styles';

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: 'flex-start',
  },
  paper: {
    width: '508px',
    height: '500px',
    borderRadius: '17px',
  },
});

const DialogContent = styled('div')({
  padding: '33px',
});

const ButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
});

const SaveBtnContainer = styled('div')({
  marginRight: '16px',
});

const Header = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '24px',
});

const SectionContainer = styled('div')({
  margin: '16px 0',
});

const SectionHeader = styled(Typography)({
  fontFamily: 'Roboto, sans-serif',
  fontSize: '14px',
});

const Divider = styled('div')({
  width: '100%',
  borderBottom: `1px solid ${colors.cloudy_blue_50}`,
  margin: '9px 0',
});

const HEIGHT_UNITS = [
  { type: 'in', label: 'Feet and Inches' },
  { type: 'cm', label: 'Centimeters' },
];

const WEIGHT_UNITS = [
  { type: 'lb', label: 'Pounds' },
  { type: 'kg', label: 'Kilograms' },
];

function RadioSection ({ value, onChange, disabled, units }) {
  return (
    <RadioGroup onChange={onChange} value={value}>
      {
        units.map((unit, index) => {
          return (
            <div key={index}>
              <RadioButton
                label={unit.label}
                value={unit.type}
                disabled={disabled}
              />
              <Divider />
            </div>
          );
        })
      }
    </RadioGroup>
  );
}

function MeasurementUnits (props) {
  const { isOpen, onClose, userUnits } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [saveState, setSaveState] = useState(SAVE_STATES.CLICKABLE);
  const [heightUnits, setHeightUnits] = useState(userUnits.unitHeight || '');
  const [weightUnits, setWeightUnits] = useState(userUnits.unitWeight || '');

  const onChangeWeightUnits = (e) => {
    setSaveState(SAVE_STATES.CLICKABLE);
    setWeightUnits(e.target.value);
  };

  const onChangeHeightUnits = (e) => {
    setSaveState(SAVE_STATES.CLICKABLE);
    setHeightUnits(e.target.value);
  };

  const onSaveMeasurementSettings = async () => {
    try {
      setSaveState(SAVE_STATES.LOADING);
      await nasmApi.users.setMeasurementSystem(heightUnits, weightUnits);
      await dispatch(getMe());
      setSaveState(SAVE_STATES.CLICKABLE);
      onClose();
    } catch {
      setSaveState(SAVE_STATES.ERROR);
      alert('Unable to save measurement units');
    }
  };

  return (
    <Dialog
      open={isOpen}
      classes={{
        scrollPaper: classes.scrollPaper,
        paper: classes.paper,
      }}
      BackdropProps={{
        style: {
          backgroundColor: colors.backdrop_tint,
          opacity: 0.35,
        },
      }}
    >
      <DialogContent>
        <ButtonContainer>
          <SaveBtnContainer>
            <SaveButton
              saveState={saveState}
              onClick={onSaveMeasurementSettings}
            />
          </SaveBtnContainer>
          <OvalButton onClick={onClose}>Close</OvalButton>
        </ButtonContainer>
        <Header>Measurement Units</Header>
        <SectionContainer>
          <SectionHeader>Height</SectionHeader>
          <Divider />
          <RadioSection
            value={heightUnits}
            onChange={onChangeHeightUnits}
            disabled={saveState === SAVE_STATES.LOADING}
            units={HEIGHT_UNITS}
          />
        </SectionContainer>
        <SectionContainer>
          <SectionHeader>Weight</SectionHeader>
          <Divider />
          <RadioSection
            value={weightUnits}
            onChange={onChangeWeightUnits}
            disabled={saveState === SAVE_STATES.LOADING}
            units={WEIGHT_UNITS}
          />
        </SectionContainer>
      </DialogContent>
    </Dialog>
  );
}

export default MeasurementUnits;

MeasurementUnits.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userUnits: PropTypes.shape({
    unitHeight: PropTypes.string,
    unitWeight: PropTypes.string,
  }).isRequired,
};
