/* eslint-disable camelcase */
import React, { useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Fade,
  ButtonBase,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { containsAccentedChars, hasEmojis } from "../../util/validate";
import { ReactComponent as RightArrow } from "../../resources/icons-arrows-buttons-right-arrow-dark-gray.svg";
import { colors } from "../../styles";
import placeholderIcon from "../../resources/user-dashboard-client-profile-with-no-photo@2x.png";
import EditIcon from "../../resources/edit-orange.png";
import RemoveGroup from "./RemoveGroup";
import nasm from "../../dataManager/apiConfig";
import MacaroniButton from "../Buttons/MacaroniButton";
import OvalButton from "../Buttons/OvalButton";
import UnderlineTextInput from "../Inputs/UnderlineTextInput";
import SelectGroupMembers from "../../pages/groups/SelectGroupMembers";
import { selectGroup } from "../../reducers/selectedGroupReducer";
import { FEATURE_FLAGS } from "../../constants";
import { deselectClient, selectClient } from "../../reducers/selectedClientReducer";

const useStyles = makeStyles({
  container: {
    overflowY: "scroll",
  },
  root: {
    padding: "28px",
  },
  statusCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "554px",
  },
  statusMsg: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "36px",
  },
  buttonsCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  topBtnCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "210px",
  },
  headerAndSubheader: {
    margin: "32px 0",
  },
  headerTitle: {
    fontSize: "40px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "8px",
  },
  subheader: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "17px",
    textAlign: "center",
  },
  textFieldCont: {
    margin: "16px 0",
  },
  inputLabel: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    color: colors.steel,
  },
  groupMembersView: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    borderRadius: "3px",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colors.divider_line_light_blue,
    padding: "5px",
    marginTop: "30px",
  },
  groupMembersCountCont: {
    display: "flex",
    alignItems: "center",
  },
  groupMembersLabel: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    color: colors.black,
  },
  groupMembersCountLabel: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    marginLeft: "5px",
    color: colors.steel,
  },
  editIcon: {
    width: "30px",
    height: "30px",
  },
  noMemberView: {
    marginTop: "30px",
    marginBottom: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  memberInfoCont: {
    display: "flex",
    borderBottomWidth: 1,
    borderBottomColor: colors.divider_line_light_blue,
    borderBottomStyle: "solid",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(94%)',
    },
  },
  memberInfoView: {
    padding: "20px",
    display: "flex",
    alignItems: "center",
  },
  profileAvatar: {
    height: "58px",
    width: "58px",
    borderRadius: "50px",
    objectFit: "cover",
  },
  clientNameLabel: {
    fontFamily: "DMSans, sans-serif",
    fontSize: "17px",
    color: colors.black,
    marginLeft: "20px",
  },
  rightArrow: {
    marginRight: "20px",
  },
});

function AddNewGroupForm(props) {
  const { visible, onClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedGroup = useSelector(
    (state) => state.selectedGroup?.group ?? {},
  );
  const isNewGroup = !selectedGroup?.id;

  const members =
    selectedGroup?.client_group_clients?.map((user) => user?.client_user) ?? [];

  const [groupName, setGroupName] = useState(selectedGroup?.title ?? "");
  const [groupMembers, setGroupMembers] = useState(members ?? []);
  const [groupNameError, setGroupNameError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [showClientsList, setShowClientsList] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [fetchingClientId, setFetchingClientId] = useState('');

  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);
  const saveGroupInfo = () => {
    if (isNewGroup) {
      createNewGroup();
    } else {
      updateGroupInfo();
    }
  };

  const createNewGroup = async () => {
    try {
      setLoading(true);
      const groupData = {
        title: groupName,
        client_user_ids: groupMembers.map((user) => user.id),
      };

      if (selectedProfile?.ClubId) {
        groupData.club_id = selectedProfile?.ClubId;
        if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && selectedProfile?.Locations?.Id) {
          groupData.location_id = selectedProfile?.Locations?.Id;
        }
      }

      const response = await nasm.api.createGroup(groupData);
      if (response) {
        alert("The group has been created.");
      }
    } catch (error) {
      alert("Something went wrong! Please try again later.");
    } finally {
      setLoading(false);
      onClose();
      refreshGroups();
    }
  };

  const updateGroupInfo = async () => {
    try {
      setLoading(true);
      const { id: groupId } = selectedGroup;
      const previousUsers = selectedGroup?.client_group_clients.map(
        (i) => i.client_user.id,
      );
      const updatedUsers = groupMembers.map((i) => i.id);
      const combinedUsers = [...previousUsers, ...updatedUsers];
      const new_client_user_ids = [];
      const removed_client_user_ids = [];
      combinedUsers.forEach((id) => {
        if (previousUsers.indexOf(id) === -1) {
          new_client_user_ids.push(id);
        }
        if (updatedUsers.indexOf(id) === -1) {
          removed_client_user_ids.push(id);
        }
      });
      const groupData = {
        id: groupId,
        title: groupName,
        new_client_user_ids,
        removed_client_user_ids,
      };
      if (selectedProfile?.ClubId) {
        groupData.club_id = selectedProfile?.ClubId;
        if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && selectedProfile?.Locations?.Id) {
          groupData.location_id = selectedProfile?.Locations?.Id;
        }
      }
      const response = await nasm.api.updateGroup(groupData);
      if (response) {
        alert("The group has been updated successfully.");
        const updatedGroup = {
          ...selectedGroup,
          title: response?.title,
          client_group_clients: response?.client_group_clients,
        };
        dispatch(selectGroup(updatedGroup));
      }
    } catch (error) {
      alert(error?.message ?? "Something went wrong! Please try again later.");
    } finally {
      setLoading(false);
      onClose();
      refreshGroups();
    }
  };

  const removeGroup = async () => {
    try {
      setLoading(true);
      const groupData = {
        id: selectedGroup?.id,
        isDelete: true,
      };
      if (selectedProfile?.ClubId) {
        groupData.club_id = selectedProfile?.ClubId;
        if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && selectedProfile?.Locations?.Id) {
          groupData.location_id = selectedProfile?.Locations?.Id;
        }
      }
      const response = await nasm.api.updateGroup(groupData);
      if (response) {
        alert('The group has been deleted successfully.');
        toggleRemoveGroupPopup();
        onClose();
        refreshGroups();
      }
    } catch (error) {
      alert('Something went wrong! Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const refreshGroups = () => {
    const refreshGroupsEvent = new CustomEvent("refreshGroups", {
      cancelable: true,
    });
    window.dispatchEvent(refreshGroupsEvent);
  };

  const onClickSave = () => {
    if (formSubmitting) {
      return;
    }
    setFormSubmitting(true);

    // Validating form fields
    if (!groupName.length || groupNameError.length) {
      setGroupNameError("Please enter a valid group name");
      setFormSubmitting(false);
      return;
    }
    if (!groupMembers.length) {
      alert("Please select your clients to create a group.");
      setFormSubmitting(false);
      return;
    }
    saveGroupInfo();
  };

  if (!visible) return null;

  if (loading) {
    return (
      <Box className={classes.statusCont}>
        <Typography className={classes.statusMsg}>Saving changes...</Typography>
        <CircularProgress />
      </Box>
    );
  }

  const toggleRemoveGroupPopup = () => {
    setShowConfirmRemove(!showConfirmRemove);
  };

  const toggleClientsListPopup = () => {
    setShowClientsList(!showClientsList);
  };

  const onUpdateClients = (updatedClients) => {
    if (!updatedClients.length) {
      alert("Please select your clients to continue.");
      return;
    }
    toggleClientsListPopup();
    setGroupMembers(updatedClients);
  };

  const goToClientProfile = (client) => {
    setFetchingClientId(client.user.id);
    nasm.api
      .getUserById(client.user.id)
      .then((user) => {
        if (user) {
          dispatch(deselectClient());
          dispatch(selectClient(user));
          history.push(`/clients/my-clients/${user?.first_name?.toLowerCase()}/dashboard`);
        }
      })
      .catch((error) => error)
      .finally(() => setFetchingClientId(''));
  };

  const renderTopButtons = () => (
    <Box className={classes.buttonsCont}>
      {selectedGroup?.id ? (
        <OvalButton width="136px" onClick={toggleRemoveGroupPopup}>
          Remove Group
        </OvalButton>
      ) : (
        <Box />
      )}
      <Box className={classes.topBtnCont}>
        <MacaroniButton width="127px" onClick={onClickSave}>
          Save
        </MacaroniButton>
        <OvalButton onClick={onClose}>Close</OvalButton>
      </Box>
    </Box>
  );

  const renderHeader = () => (
    <Box className={classes.headerAndSubheader}>
      <Typography className={classes.headerTitle}>
        {selectedGroup?.title ?? "Add New Group"}
      </Typography>
      <Typography className={classes.subheader}>
        Chat and assign workouts, programs, and exercises to a group of clients.
      </Typography>
    </Box>
  );

  const renderGroupName = () => (
    <Box className={classes.textFieldCont}>
      <Typography className={classes.inputLabel}>Group Name</Typography>
      <UnderlineTextInput
        value={groupName}
        placeholder="Enter a group name"
        onNameChanged={(e) => {
          setGroupNameError("");
          const newGroupName = e.target.value;
          if (containsAccentedChars(newGroupName) || hasEmojis(newGroupName)) {
            setGroupNameError("Please enter a valid group name.");
          }
          setGroupName(newGroupName);
        }}
        showError={groupNameError.length > 0}
        errorColor={colors.red}
        errorText={groupNameError}
      />
    </Box>
  );

  const renderGroupMembersCount = () => (
    <Box className={classes.groupMembersView}>
      <Box className={classes.groupMembersCountCont}>
        <Typography className={classes.groupMembersLabel}>
          Group Members:
        </Typography>
        <Typography className={classes.groupMembersCountLabel}>
          {groupMembers.length}
        </Typography>
      </Box>
      <ButtonBase onClick={toggleClientsListPopup}>
        <img className={classes.editIcon} src={EditIcon} alt="edit-icon" />
      </ButtonBase>
    </Box>
  );

  const renderGroupMembers = () => {
    if (!groupMembers.length) {
      return (
        <Box className={classes.noMemberView}>
          <Typography>No clients added yet!</Typography>
        </Box>
      );
    }
    return groupMembers.map((client) => (
      <Box className={classes.memberInfoCont} onClick={() => goToClientProfile(client)}>
        <Box className={classes.memberInfoView}>
          <img
            className={classes.profileAvatar}
            src={client.user.avatar_url || placeholderIcon}
            alt="Avatar"
          />
          <Typography className={classes.clientNameLabel}>
            {client.user.full_name}
          </Typography>
        </Box>
        {fetchingClientId === client.user.id ? (
           <CircularProgress className={classes.rightArrow} />
        ) : (
          <RightArrow className={classes.rightArrow} />
        )}
      </Box>
    ));
  };

  return (
    <>
      <Fade in={visible} mountOnEnter unmountOnExit timeout={500}>
        <Box className={classes.container}>
          {showConfirmRemove ? (
            <RemoveGroup
              open={showConfirmRemove}
              onClose={toggleRemoveGroupPopup}
              onDeleteGroup={removeGroup}
            />
          ) : showClientsList ? (
            <SelectGroupMembers
              open={showClientsList}
              onClose={toggleClientsListPopup}
              selectedClients={groupMembers}
              onUpdateClients={onUpdateClients}
            />
          ) : (
            <>
              <Box className={classes.root}>
                {renderTopButtons()}
                {renderHeader()}
                {renderGroupName()}
                {renderGroupMembersCount()}
              </Box>
              {renderGroupMembers()}
            </>
          )}
        </Box>
      </Fade>
    </>
  );
}

export default AddNewGroupForm;
