import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Slide, RadioGroup } from '@material-ui/core';

import nasmApi from '../../api/endpoints';
import { selectClient } from '../../reducers/selectedClientReducer';
import ActionButton from '../Buttons/ActionButton';
import RadioButton from '../Buttons/RadioButton';
import { colors } from '../../styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '0 28px',
    height: '534px',
  },
  btnCont: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  description: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    textAlign: 'center',
  },
  radioCont: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  radioBtns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  focusText: {
    fontSize: '14px',
    fontFamily: 'Roboto, sans-serif',
    color: colors.steel,
  },
  divider: {
    width: '375px',
    borderBottom: `1px solid ${colors.cloudy_blue_50}`,
    margin: '9px 0',
  },
});

function SetGoals (props) {
  const { visible, client, onGoalSet } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedRadio, setSelectedRadio] = useState('');
  const [allGoals, setAllGoals] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    nasmApi.goals.getAllGoals().then((result) => {
      setAllGoals(result.result);
    });
  }, []);

  const onSelectGoal = (e) => {
    setSelectedRadio(e.target.value);
  };

  const onClickContinue = () => {
    if (!selectedRadio) return;
    setLoading(true);
    nasmApi.users.updateClientUser({ goal_id: selectedRadio }, client.id).then(() => {
      dispatch(selectClient(client));
      onGoalSet();
    }).catch((error) => {
      alert(error.message);
    }).finally(() => {
      setLoading(false);
    });
  };

  if (!visible) return null;

  return (
    <Slide
      in={visible}
      direction='left'
      timeout={500}
      mountOnEnter
      unmountOnExit
    >
      <Box className={classes.root}>
        <Box className={classes.btnCont}>
          <ActionButton
            loading={loading}
            label='Continue'
            onClick={onClickContinue}
            width={95}
          />
        </Box>
        <Box>
          <Typography className={classes.header}>{`${client.first_name ?? 'Client'}'s Goal`}</Typography>
          <Typography className={classes.description}>Choose a main focus. You can change this anytime.</Typography>
        </Box>
        <Box className={classes.radioCont}>
          <Typography className={classes.focusText}>Focus</Typography>
          <RadioGroup
            defaultValue={allGoals[0]?.id}
            aria-label='focus'
            name='focus-radios'
            value={selectedRadio}
            onChange={onSelectGoal}
            className={classes.radioBtns}
          >
            {
              allGoals.map((goal, index) => {
                return (
                  <Box>
                    <Box className={classes.divider} />
                    <RadioButton key={index} label={goal.name} value={goal.id} />
                  </Box>
                );
              })
            }
          </RadioGroup>
        </Box>
      </Box>
    </Slide>
  );
}

export default SetGoals;
