import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Link, useLocation } from 'react-router-dom';

import Panel from './Panel';
import OvalButton from '../Buttons/OvalButton';
import HighlightSearchBar from '../Inputs/HighlightSearchBar';
import exerciseThumbnailPlaceholder from '../../resources/image-exercise-placeholder.png';
import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';
import { colors } from '../../styles';
import FadeTransition from '../Transitions/FadeTransition';
import FilterMenu from '../Schedule/FilterMenu';
import AddExerciseListSkeleton from '../LoadingStates/AddExerciseListSkeleton';

import useFetchExercisesList from '../../hooks/exercises/FetchExercisesList';
import { usePaginationObserver } from '../../util/utilFunctions';
import { saveExercises, clearExercises } from '../../reducers/exercisesReducer';
import useFetchMuscleGroupsList from '../../hooks/FetchMuscleGroupsList';
import useFetchFitnessComponentsList from '../../hooks/FetchFitnessComponentsList';
import useFetchExerciseFilters from '../../hooks/exercises/FetchExerciseFilters';

import dragHandleImg from '../../resources/img-sort-gray-active.svg';
import {ClearOptions} from '../Inputs';
import nasmApi from '../../api/endpoints';
import { clearCompoundRoutines, saveCompoundRoutines } from '../../reducers/compoundRoutineReducer';
import ROUTINE_TYPES from '../../util/RoutineTypes';
import { EXERCISE_TYPE } from '../../constants';
import progressionIcon from '../../resources/progressionIcon.png';
import regressionIcon from '../../resources/regressionIcon.png';

const useStylesExerciseRow = makeStyles({
  root: props => ({
    borderCollapse: 'collapse',
    paddingTop: '14px',
    paddingBottom: '14px',
    paddingLeft: '30px',
    backgroundColor: props.selectedId && props.selectedId === props.draggableId ? colors.baby_blue : colors.white,

    borderTop: props.isDragging ? `2px solid ${colors.yellow_button_style}` : 'none',
    borderLeft: props.isDragging ? `2px solid ${colors.yellow_button_style}` : 'none',
    borderRight: props.isDragging ? `2px solid ${colors.yellow_button_style}` : 'none',
    borderBottom: props.isDragging ? `2px solid ${colors.yellow_button_style}` : 'none',
    // hack to add in a bottomBorder that doesn't completely fill the row from left to right
    '&::after': {
      content: '""',
      position: 'relative',
      left: -40,
      bottom: -15,
      height: '1px',
      width: '100%',
      display: 'block',
      borderBottom: props.isDragging ? 'none' : '1px solid #b6bdc3',
    },
    '&:hover': {
      backgroundColor: colors.baby_blue,
    },
  }),
  contents: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '50px',
  },
  name: {
    paddingLeft: '20px',
    fontSize: '17px',
    color: colors.black,
    width: '70%',
  },
  thumbnail: {
    borderRadius: 6,
  },
  imageContainer: {
    display: 'flex',
    position: 'relative',
  },
  progressionRegressionView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    position: 'absolute',
    width: 76,
    height: 48,
    justifyContent: 'flex-end',
  },
  progressionRegressionIcon: {
    width: 17,
    height: 17,
    marginBottom: 3,
    marginRight: 3,
    marginLeft: 2,
  },
});

const useSupersetStyles = makeStyles({
  supersetListHeading: {
    marginTop: '10px',
    borderTop: `1px solid ${colors.light_grey}`,
    borderBottom: `1px solid ${colors.light_grey}`,
    backgroundColor: colors.checkbox_grey,
    padding: "10px 25px",
  },
  exerciseListHeading: {
    borderTop: `1px solid ${colors.light_grey}`,
    borderBottom: `1px solid ${colors.light_grey}`,
    backgroundColor: colors.checkbox_grey,
    padding: "10px 25px",
  },
  ListHeadingText: {
    fontFamily: "DMSans, sans-serif",
    fontWeight: 500,
    fontSize: 14,
  },
});

function RenderProgressionRegressionIconImage (exercise) {
  const classes = useStylesExerciseRow();
  const hasProgression = exercise?.progressions_regressions?.some(
    (ex) => ex.exerciseType === EXERCISE_TYPE.PROGRESSION,
  );
  const hasRegression = exercise?.progressions_regressions?.some(
    (ex) => ex.exerciseType === EXERCISE_TYPE.REGRESSION,
  );

  return (
    <Box className={classes.progressionRegressionView}>
      {hasProgression ? (
        <img
          src={progressionIcon}
          className={classes.progressionRegressionIcon}
          alt={'Prog'}
          onError={(e) => {
            e.target.src = progressionIcon;
          }}
        />
      ) : null}
      {hasRegression ? (
        <img
          src={regressionIcon}
          className={classes.progressionRegressionIcon}
          alt={'Reg'}
          onError={(e) => {
            e.target.src = regressionIcon;
          }}
        />
      ) : null}
    </Box>
  );
}

const ExerciseRow = React.forwardRef((props, ref) => {
  const { name, viewExerciseLink, draggableId, setSelectedId, dragHandleProps } = props;
  const classes = useStylesExerciseRow(props);
  const isSuperSet = props.data.routine_type === ROUTINE_TYPES.SUPER_SET;

  const onClickCompoundRoutine = () => {
    if(props.isCompoundRoutines){
      if (isSuperSet) {
        props.setSuperSetData(props.data);
      } else {
        props.setCircuitsData(props.data);
      }
    }
  };

  return (
    <Link to={{ search: props.isCompoundRoutines 
        ? viewExerciseLink(true) 
        : viewExerciseLink(draggableId)}}>
      <Box 
        ref={ref} className={classes.root} 
        onClick={() => {
          onClickCompoundRoutine();
          setSelectedId(draggableId);
          }}>
          <Box className={classes.contents}>
            <img alt='drag handle' src={dragHandleImg} {...dragHandleProps} style={{ paddingRight: 20 }} />
            {!props.isCompoundRoutines && 
              <div className={classes.imageContainer}>
                <img
                  className={classes.thumbnail}
                  alt='exercise-thumbnail'
                  src={props.imgUrl || exerciseThumbnailPlaceholder}
                  width={76} height={48}
                  onError={e => {
                    e.target.src = exerciseThumbnailPlaceholder;
                  }}
                />
                {props.data?.progressions_regressions?.length
                  ? RenderProgressionRegressionIconImage(props.data)
                  : null}
              </div>
            }
            <div className={classes.name}>{name}</div>
          </Box>
      </Box>
    </Link>
  );
});

function ExerciseRowPlaceholder(props) {
  const { name } = props;
  const classes = useStylesExerciseRow();

  return (
    <Box className={classes.root}>
      <Box className={classes.contents}>
        <img alt='drag handle placeholder' src={dragHandleImg} style={{ paddingRight: 20 }} />
        {!props.isCompoundRoutines && 
          <div className={classes.imageContainer}>
            <img
              className={classes.thumbnail}
              alt='exercise-thumbnail'
              src={props.imgUrl || exerciseThumbnailPlaceholder}
              width={76} height={48}
              onError={e => {
                e.target.src = exerciseThumbnailPlaceholder;
              }}
            />
            {props.data?.progressions_regressions?.length
              ? RenderProgressionRegressionIconImage(props.data)
              : null}
          </div>
          }
        <div className={classes.name}>{name}</div>
      </Box>
    </Box>
  );
}

const DraggableExerciseRow = React.forwardRef((props, ref) => {
  const {
    draggableId,
    index,
    name,
    viewExerciseLink,
    selectedId,
    setSelectedId,
    isDragDisabled,
  } = props;

  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => {
        return (
          <>
            <Box
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <ExerciseRow
                ref={ref}
                isDragging={snapshot.isDragging}
                name={name}
                imgUrl={props.imgUrl}
                viewExerciseLink={viewExerciseLink}
                draggableId={draggableId}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                dragHandleProps={provided.dragHandleProps}
                isCompoundRoutines={props.isCompoundRoutines}
                setSuperSetData={props.setSuperSetData}
                setCircuitsData={props.setCircuitsData}
                data={props.data}
              />
            </Box>
            {snapshot.isDragging &&
              <ExerciseRowPlaceholder
                name={name}
                imgUrl={props.imgUrl}
                isCompoundRoutines={props.isCompoundRoutines}
                data={props.data}
              />
            }
          </>
        );
      }}
    </Draggable>
  );
});

const ExerciseList = React.forwardRef(
  (props, ref) => {
    const {
      viewExerciseLink,
      selectedExerciseDraggableId,
      setSelectedExerciseDraggableId,
      isDragDisabled,
      exerciseData,
      isLoading,
      rootElement,
      compoundRoutines,
      isSuperSetLoading,
      setSupersetPageNumber,
      hasMoreSuperset,
      viewSuperSetsLink,
      setSuperSetData,
      viewCircuitLink,
      setCircuitsData,
    } = props;
    const classes = useSupersetStyles(props);

    const records = [];

    const lastSuperSetRowRef = usePaginationObserver({
      setPageNumber: setSupersetPageNumber,
      isLoading: isSuperSetLoading,
      hasMoreDataToLoad: hasMoreSuperset,
      rootElement: rootElement.current,
    });

    const allCircuits = compoundRoutines?.filter(
      (routine) => routine.routine_type === ROUTINE_TYPES.CIRCUIT,
    );

    if (allCircuits.length) {
      records.push(
        <Box className={classes.supersetListHeading}>
          <Typography className={classes.ListHeadingText}>
            Circuits
          </Typography>
        </Box>,
      );
    }

    for (let i = 0; i < allCircuits.length; ++i) {
      const data = allCircuits[i];

      if (allCircuits.length === i + 1) {
        // Add ref to last exercise row being rendered to trigger another API call
        // to fetch the next batch of exercise data
        records.push(
          <DraggableExerciseRow
            ref={lastSuperSetRowRef}
            key={data.id}
            index={i}
            value={data.id}
            draggableId={data.id}
            name={data.title}
            viewExerciseLink={viewCircuitLink}
            selectedId={selectedExerciseDraggableId}
            setSelectedId={setSelectedExerciseDraggableId}
            isDragDisabled={isDragDisabled}
            isCompoundRoutines={true}
            setCircuitsData={setCircuitsData}
            data={data}
          />,
        );
      } else {
        records.push(
          <DraggableExerciseRow
            key={data.id}
            index={i}
            value={data.id}
            draggableId={data.id}
            name={data.title}
            viewExerciseLink={viewCircuitLink}
            selectedId={selectedExerciseDraggableId}
            setSelectedId={setSelectedExerciseDraggableId}
            isDragDisabled={isDragDisabled}
            isCompoundRoutines={true}
            setCircuitsData={setCircuitsData}
            data={data}
          />,
        );
      }
    }
  
    const allSuperSets = compoundRoutines?.filter(
      (routine) => routine.routine_type === ROUTINE_TYPES.SUPER_SET,
    );

    if (allSuperSets.length) {
      records.push(
        <Box className={classes.supersetListHeading}>
          <Typography className={classes.ListHeadingText}>
            Super Sets
          </Typography>
        </Box>,
      );
    }
    
    for (let i = 0; i < allSuperSets.length; ++i) {
      const data = allSuperSets[i];

      if (allSuperSets.length === i + 1) {
        // Add ref to last exercise row being rendered to trigger another API call
        // to fetch the next batch of exercise data
        records.push(
          <DraggableExerciseRow
            ref={lastSuperSetRowRef}
            key={data.id}
            index={i}
            value={data.id}
            draggableId={data.id}
            name={data.title}
            viewExerciseLink={viewSuperSetsLink}
            selectedId={selectedExerciseDraggableId}
            setSelectedId={setSelectedExerciseDraggableId}
            isDragDisabled={isDragDisabled}
            isCompoundRoutines={true}
            setSuperSetData={setSuperSetData}
            data={data}
          />,
        );
      } else {
        records.push(
          <DraggableExerciseRow
            key={data.id}
            index={i}
            value={data.id}
            draggableId={data.id}
            name={data.title}
            viewExerciseLink={viewSuperSetsLink}
            selectedId={selectedExerciseDraggableId}
            setSelectedId={setSelectedExerciseDraggableId}
            isDragDisabled={isDragDisabled}
            isCompoundRoutines={true}
            setSuperSetData={setSuperSetData}
            data={data}
          />,
        );
      }
    }

    if (isSuperSetLoading) {
      records.push(
        <div key={records.length} style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={20} />
        </div>);
    }

    if (exerciseData.length) {
      records.push(
        <Box className={classes.exerciseListHeading}>
        <Typography className={classes.ListHeadingText}>
          My Exercises
        </Typography>
      </Box>,
      );
    }

    for (let i = 0; i < exerciseData.length; ++i) {
      const data = exerciseData[i];

      if (exerciseData.length === i + 1) {
        // Add ref to last exercise row being rendered to trigger another API call
        // to fetch the next batch of exercise data
        records.push(
          <DraggableExerciseRow
            ref={ref}
            key={data.id}
            index={i + allSuperSets.length}
            value={data.id}
            draggableId={data.id}
            name={data.name}
            imgUrl={data.image_url}
            viewExerciseLink={viewExerciseLink}
            selectedId={selectedExerciseDraggableId}
            setSelectedId={setSelectedExerciseDraggableId}
            isDragDisabled={isDragDisabled}
            isCompoundRoutines={false}
            data={data}
          />,
        );
      } else {
        records.push(
          <DraggableExerciseRow
            key={data.id}
            index={i + allSuperSets.length}
            value={data.id}
            draggableId={data.id}
            name={data.name}
            imgUrl={data.image_url}
            viewExerciseLink={viewExerciseLink}
            selectedId={selectedExerciseDraggableId}
            setSelectedId={setSelectedExerciseDraggableId}
            isDragDisabled={isDragDisabled}
            isCompoundRoutines={false}
            data={data}
          />,
        );
      }
    }

    // Loading spinner once trainer has scrolled to the bottom of the current page
    if (isLoading && !hasMoreSuperset) {
      records.push(
        <div key={records.length} style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={20} />
        </div>);
    }

    return (
      <Droppable droppableId='Exercise-List' isDropDisabled>
        {(provided) => {
          return (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {records}
              {provided.placeholder}
            </Box>
          );
        }}
      </Droppable>
    );
  });

const useStyles = makeStyles({
  boxContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  boxPadding: {
    padding: '7px',
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 20px 0px 0px',
  },
  addExercises: {
    paddingLeft: '40px',
    paddingBottom: '20px',
  },
  addExercisesText: {
    fontSize: '24px',
    lineHeight: 1.63,
    fontWeight: 'bold',
    fontStyle: 'normal',
    paddingBottom: '10px',
  },
  list: {
    height: '100%',
    overflowY: 'auto',
  },
  exercisesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
  },
});

function ExercisesContainer (props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    viewWorkoutDetailsLink,
    viewExerciseLink,
    selectedExerciseDraggableId,
    setSelectedExerciseDraggableId,
    resetSelection,
    isDragDisabled,
    viewSuperSetsLink,
    setSuperSetData,
    viewCircuitLink,
    setCircuitsData,
  } = props;

  const [searchText, setSearchText] = useState('');
  const [pageNumber, setPageNumber] = useState(1);

  const { exerciseFilters } = useFetchExerciseFilters();
  const { muscleGroups } = useFetchMuscleGroupsList();
  const { fitnessComponents } = useFetchFitnessComponentsList();
  const [sourceFilters, setSourceFilters] = useState([]);
  const [muscleGroupFilters, setMuscleGroupFilters] = useState([]);
  const [fitnessComponentFilters, setFitnessComponentFilters] = useState([]);

  const [pendingSourceFilters, setPendingSourceFilters] = useState([]);
  const [pendingMuscleGroupFilters, setPendingMuscleGroupFilters] = useState([]);
  const [pendingFitnessComponentFilters, setPendingFitnessComponentFilters] = useState([]);
  const [supersetPageNumber, setSupersetPageNumber] = useState(1);
  const [hasMoreSuperset, setHasMoreSuperset] = useState(true);
  const [isSuperSetLoading, setIsSuperSetLoading] = useState(false);
  const [compoundRoutines, setCompoundRoutines] = useState([]);

  useEffect(() => {
    fetchAllSuperSet();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, supersetPageNumber]);

  const fetchAllSuperSet = async () => {
    const jsonData = {
      page: supersetPageNumber,
      size: 20,
      search: searchText,
    };
    if(hasMoreSuperset) {
      setIsSuperSetLoading(true);
      try {
        const res = await nasmApi.supersets.fetchAllSuperSets(jsonData);
        if (res) {
            setHasMoreSuperset(res?.result?.hasMore);
            setCompoundRoutines((prevState) => ( jsonData.page === 1
              ? [...res?.result?.compoundRoutines]
              : [...prevState, ...res?.result?.compoundRoutines]));
            setIsSuperSetLoading(false);
        }
      } catch (error) {
        alert(error?.message || 'Something went wrong! Please try again later.');
      }
    }
  };

  // This is needed so that the compund Routines can be looked up via id
  // when dropping an compound Routine into WorkoutSectionsPanel

  useEffect(() => {
    if (compoundRoutines && compoundRoutines.length > 0) {
      dispatch(saveCompoundRoutines(compoundRoutines));
    }
    return () => { dispatch(clearCompoundRoutines()); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compoundRoutines]);

  const {
    exerciseData,
    loading,
    hasMore,
    error,
  } = useFetchExercisesList({
    searchText: searchText,
    pageNumber: pageNumber,
    sizePerPage: 20,
    exercisesFilter: sourceFilters.join(','),
    muscleGroupIds: muscleGroupFilters.join(','),
    fitnessComponentIds: fitnessComponentFilters.join(','),
    shouldFetch: !hasMoreSuperset,
  });

  // This is needed so that the exercise can be looked up via exercise id
  // when dropping an exercise into WorkoutSectionsPanel
  useEffect(() => {
    if (!loading & !error && exerciseData && exerciseData.length > 0) {
      dispatch(saveExercises(exerciseData));
    }

    // Used to remove exercises from redux once the component gets unmounted.
    // Used to prevent duplicate exercise key warning React logs in the console.
    return () => { dispatch(clearExercises()); };
  }, [exerciseData, hasMore, pageNumber, searchText, dispatch, loading, error]);

  const rootElement = useRef();
  const lastExerciseRowRef = usePaginationObserver({
    setPageNumber: setPageNumber,
    isLoading: loading,
    hasMoreDataToLoad: hasMore,
    rootElement: rootElement.current,
  });

  function resetSuperSetData () {
    setSupersetPageNumber(1);
    setHasMoreSuperset(true);
    setCompoundRoutines([]);
  }

  function handleSearch (e) {
    setSearchText(e.target.value);
    setPageNumber(1);
    resetSelection();
    resetSuperSetData();
  }

  function resetSearch () {
    setPageNumber(1);
    setSearchText('');
    resetSelection();
    resetSuperSetData();
  }

  const location = useLocation();

  const handleFilters = (id, updateActiveFilters) => {
    updateActiveFilters(prevState => {
      const filtersCopy = [...prevState];
      const idIndex = filtersCopy.indexOf(id);

      // remove from filters array if id exists
      if (idIndex !== -1) {
        filtersCopy.splice(idIndex, 1);
      } else {
        // add id to filters array
        filtersCopy.push(id);
      }

      return [...(new Set([...filtersCopy]))];
    });

    setPageNumber(1);
  };

  const clearAllFilters = () => {
    setSourceFilters([]);
    setMuscleGroupFilters([]);
    setFitnessComponentFilters([]);
    setPendingSourceFilters([]);
    setPendingMuscleGroupFilters([]);
    setPendingFitnessComponentFilters([]);
    setPageNumber(1);
  };

  const onClickClear = () => {
    setSearchText('');
    setPageNumber(1);
    resetSuperSetData();
  };

  const onCloseFilter = () => {
    setSourceFilters(pendingSourceFilters);
    setFitnessComponentFilters(pendingFitnessComponentFilters);
    setMuscleGroupFilters(pendingMuscleGroupFilters);
    setPageNumber(1);
  };

  const ExerciseListContents = () => {
    const isInitialLoading = loading && compoundRoutines.length === 0;
    if (isInitialLoading) {
      return <AddExerciseListSkeleton />;
    }

    return (
      <ExerciseList
        viewExerciseLink={viewExerciseLink}
        selectedExerciseDraggableId={selectedExerciseDraggableId}
        setSelectedExerciseDraggableId={setSelectedExerciseDraggableId}
        isDragDisabled={isDragDisabled}
        exerciseData={exerciseData}
        ref={lastExerciseRowRef}
        isLoading={loading}
        compoundRoutines={compoundRoutines}
        isSuperSetLoading={isSuperSetLoading}
        setSupersetPageNumber={setSupersetPageNumber}
        hasMoreSuperset={hasMoreSuperset}
        rootElement={rootElement}
        viewSuperSetsLink={viewSuperSetsLink}
        viewCircuitLink={viewCircuitLink}
        setSuperSetData={setSuperSetData}
        setCircuitsData={setCircuitsData}
      />
    );
  };

  return (
    <Box className={classes.exercisesContainer}>
      <Box className={classes.closeButton}>
        <Link
          to={{
            search: viewWorkoutDetailsLink(),
            state: location?.state ? { ...location?.state } : {},
          }}
          onClick={resetSelection}
        >
          <OvalButton onClick={resetSearch}>Close</OvalButton>
        </Link>
      </Box>
      <Box className={classes.addExercises}>
        <Typography className={classes.addExercisesText}>
          Add Exercises
        </Typography>
        <Box className={classes.boxContainer}>
          <Box style={{ paddingRight: '14px' }}>
            <HighlightSearchBar
              isClearable={searchText.length > 0}
              value={searchText}
              onClickClear={onClickClear}
              onChange={handleSearch}
            />
          </Box>
          <FilterMenu
            sources={exerciseFilters}
            muscleGroups={muscleGroups}
            fitnessComponents={fitnessComponents}
            // Current active filters
            sourceFilters={pendingSourceFilters}
            muscleGroupFilters={pendingMuscleGroupFilters}
            fitnessComponentFilters={pendingFitnessComponentFilters}
            handleSourceFilters={source => handleFilters(source, setPendingSourceFilters)}
            handleMuscleGroupFilters={muscle => handleFilters(muscle, setPendingMuscleGroupFilters)}
            handleFitnessComponentFilters={component => handleFilters(component, setPendingFitnessComponentFilters)}
            activeFilterCount={sourceFilters.length + muscleGroupFilters.length + fitnessComponentFilters.length}
            onClose={onCloseFilter}
          />
          <ClearOptions
            text='Clear Filters'
            onClick={clearAllFilters}
            visible={sourceFilters.length + muscleGroupFilters.length + fitnessComponentFilters.length > 0}
          />
        </Box>
      </Box>
      <Box ref={rootElement} className={classes.list}>
        <ExerciseListContents />
      </Box>
      <br />
    </Box>
  );
}

export default function AddExercisesPanel (props) {
  const { visible } = props;

  return (
    <SlidingFlexTransition visible={visible}>
      <Panel height={800}>
        <FadeTransition visible={visible}>
          <ExercisesContainer {...props} />
        </FadeTransition>
      </Panel>
    </SlidingFlexTransition>
  );
}
