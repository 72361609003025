import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Box, Menu, MenuItem, Typography, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PlusButtonBlue from '../../resources/create-new-active.svg';
import PlusButtonOrange from '../../resources/button-create-new.svg';
import { colors } from '../../styles';

import { useDispatch } from 'react-redux';
import { scheduleProgramContext, clearProgramContext } from '../../reducers/programContextReducer';

const useStyles = makeStyles({
  floatingButton: props => ({
    position: 'absolute',
    top: '170px',
    width: '68px',
    height: '68px',
    borderRadius: '34px',
    cursor: 'pointer',
    '&:hover': {
      filter: props.isMenuOpen ? 'brightness(100%)' : 'brightness(94%)',
    },
    // https://stackoverflow.com/questions/1417934/how-to-prevent-scrollbar-from-repositioning-web-page
    // prevent scrollbar from moving this button to the left or right
    paddingLeft: 'calc(100vw - 100%)',
    right: '150px',
  }),
  buttonBase: {
    width: '68px',
    height: '68px',
    borderRadius: '34px',
  },
  menuPopover: {
    // used to shift menu down
    marginTop: '17px',
  },
  menu: {
    width: '250px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  dropdownHeader: {
    width: '250px',
    height: '58px',
    backgroundColor: 'rgba(240, 241, 243, 0.41)',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  dropdownTitle: {
    fontSize: '14px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'inherit',
    height: 'inherit',
  },
  popoverPaper: {
    boxShadow: '0 3px 2px 0 rgba(0, 0, 0, 0.38)',
    borderRadius: '6px',
    objectFit: 'contain',
    border: `solid 1px ${colors.selected_highlight_copy_6}`,
  },
});

function FloatingButtonDropdown (props) {
  const {
    isMenuOpen,
    setMenuOpen = () => {},
    dropdownHeader,
    navigationItems = [],
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles({ isMenuOpen });

  const dispatch = useDispatch();

  const onOpenDropdown = e => {
    setAnchorEl(e.target);
    setMenuOpen(true);
    dispatch(scheduleProgramContext());
  };

  const onCloseDropdown = e => {
    setAnchorEl(null);
    setMenuOpen(false);
    dispatch(clearProgramContext());
  };

  return (
    <>
      <Box className={classes.floatingButton}>
        {/* Add ripple effect */}
        <ButtonBase className={classes.buttonBase}>
          <img
            onClick={onOpenDropdown}
            src={isMenuOpen ? PlusButtonBlue : PlusButtonOrange}
            alt='Add'
          />
        </ButtonBase>
      </Box>
      <Menu
        className={classes.menuPopover}
        MenuListProps={{
          className: classes.menu,
        }}
        PopoverClasses={{
          paper: classes.popoverPaper,
        }}
        keepMounted
        open={isMenuOpen}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        onClose={onCloseDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.dropdownHeader}>
          <Typography className={classes.dropdownTitle}>
            { dropdownHeader }
          </Typography>
        </Box>
        { navigationItems.map((navItem, index) => {
          return (
            <MenuLink
              key={index}
              label={navItem.label}
              onClick={navItem.onClick}
            />
          );
        })}
      </Menu>
    </>
  );
}

const useMenuLinkStyles = makeStyles({
  menuItem: {
    '&:hover': {
      backgroundColor: colors.selected_highlight_copy_6,
    },
  },
  menuListText: {
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: 2.29,
  },
  removeLinkDecorations: {
    textDecoration: 'none',
    color: colors.black,
    '&:hover': {
      color: colors.black,
    },
  },
});

function MenuLink (props) {
  const { label, onClick } = props;
  const classes = useMenuLinkStyles();
  return (
    <MenuItem className={classes.menuItem} onClick={onClick}>
      <Typography className={classes.menuListText}>
        {label}
      </Typography>
    </MenuItem>
  );
}

FloatingButtonDropdown.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
  dropdownHeader: PropTypes.string,
  navigationItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  })),
};

export default FloatingButtonDropdown;
