import React, { useRef } from 'react';
import { Box, CircularProgress, List, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles';
import { SquareCheckbox } from '../Inputs';

import { usePaginationObserver } from '../../util/utilFunctions';
import NoSearchResults from '../EmptyStates/NoSearchResults';

import exerciseImagePlaceholder from '../../resources/image-exercise-placeholder@2x.png';
import ROUTINE_TYPES from '../../util/RoutineTypes';

import progressionIcon from '../../resources/progressionIcon.png';
import regressionIcon from '../../resources/regressionIcon.png';
import { EXERCISE_TYPE } from '../../constants';

const useListStyle = makeStyles({
  list: {
    // hides scrollbar if mouse isn't hovering over the list
    overflowY: 'hidden',
    height: '90%',
    '&:hover': {
      overflowY: 'auto',
    },
  },
  exerciseListHeading: {
    borderTop: `1px solid ${colors.light_grey}`,
    borderBottom: `1px solid ${colors.light_grey}`,
    backgroundColor: colors.checkbox_grey,
    padding: "10px 25px",
  },
  ListHeadingText: {
    fontFamily: "DMSans, sans-serif",
    fontWeight: 500,
    fontSize: 14,
  },
});

export default function ExerciseScheduleList (props) {
  const {
    searchText,
    loading,
    hasMore,
    exerciseData,
    error,
    exerciseCheckFlags,
    onToggleCheckmark,
    setPageNumber,
    activeFilterCount,
    setSupersetPageNumber,
    hasMoreSuperset,
    isSuperSetLoading,
    compoundRoutines,
  } = props;

  const rootElement = useRef();
  const lastItemRef = usePaginationObserver({
    isLoading: loading,
    hasMoreDataToLoad: hasMore,
    setPageNumber: setPageNumber,
    rootElement: rootElement.current,
  });

  const lastSuperSetItemRef = usePaginationObserver({
    setPageNumber: setSupersetPageNumber,
    isLoading: isSuperSetLoading,
    hasMoreDataToLoad: hasMoreSuperset,
    rootElement: rootElement.current,
  });

  const allCircuits = compoundRoutines?.filter(
    (compoundRoutine) => compoundRoutine.routine_type === ROUTINE_TYPES.CIRCUIT,
  );

  const allSuperSets = compoundRoutines?.filter(
    (compoundRoutine) => compoundRoutine.routine_type === ROUTINE_TYPES.SUPER_SET,
  );

  const classes = useListStyle();

  if ((activeFilterCount > 0 || searchText !== '') && 
      exerciseData.length === 0 && compoundRoutines.length === 0 && !loading
    ) {
    return <NoSearchResults />;
  }

  return (
    <List ref={rootElement} className={classes.list}>
      {allCircuits?.length ?
        (<Box className={classes.exerciseListHeading}>
          <Typography className={classes.ListHeadingText}>
            Circuits
          </Typography>
        </Box>) : null
      }

      {allCircuits?.map((exercise, index) => {
        // last compound Routine in the paginated list
        if (allCircuits.length === index + 1) {
          return (
            <ExerciseScheduleItem
              key={exercise.id}
              ref={lastSuperSetItemRef}
              exercise={exercise}
              isChecked={!!exerciseCheckFlags[exercise.id]}
              onToggleCheckmark={onToggleCheckmark}
              isCompoundRoutines={true}
            />
          );
        }

        return (
          <ExerciseScheduleItem
            key={exercise.id}
            exercise={exercise}
            isChecked={!!exerciseCheckFlags[exercise.id]}
            onToggleCheckmark={onToggleCheckmark}
            isCompoundRoutines={true}
          />
        );
      })}

      {allSuperSets?.length ?
        (<Box className={classes.exerciseListHeading}>
          <Typography className={classes.ListHeadingText}>
            Super Sets
          </Typography>
        </Box>) : null
      }

      {allSuperSets?.map((exercise, index) => {
        // last compound Routine in the paginated list
        if (allSuperSets.length === index + 1) {
          return (
            <ExerciseScheduleItem
              key={exercise.id}
              ref={lastSuperSetItemRef}
              exercise={exercise}
              isChecked={!!exerciseCheckFlags[exercise.id]}
              onToggleCheckmark={onToggleCheckmark}
              isCompoundRoutines={true}
            />
          );
        }

        return (
          <ExerciseScheduleItem
            key={exercise.id}
            exercise={exercise}
            isChecked={!!exerciseCheckFlags[exercise.id]}
            onToggleCheckmark={onToggleCheckmark}
            isCompoundRoutines={true}
          />
        );
      })}

      {exerciseData?.length ?
        (<Box className={classes.exerciseListHeading}>
          <Typography className={classes.ListHeadingText}>
            My Exercises
          </Typography>
        </Box>) : null
      }

      {exerciseData?.map((exercise, index) => {
        // last item in the paginated list
        if (exerciseData.length === index + 1) {
          return (
            <ExerciseScheduleItem
              key={exercise.id}
              ref={lastItemRef}
              exercise={exercise}
              isChecked={!!exerciseCheckFlags[exercise.id]}
              onToggleCheckmark={onToggleCheckmark}
            />
          );
        }

        return (
          <ExerciseScheduleItem
            key={exercise.id}
            exercise={exercise}
            isChecked={!!exerciseCheckFlags[exercise.id]}
            onToggleCheckmark={onToggleCheckmark}
          />
        );
      })}
      {loading && !error &&
        <ListItem key='loading spinner' style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={20} />
        </ListItem>}
    </List>
  );
}

const useStyles = makeStyles({
  listItem: props => ({
    borderBottom: props.isChecked ? '1px solid rgba(255, 255, 255, 0.5)' : `1px solid ${colors.cloudy_blue_50}`,
    borderCollapse: 'collapse',
    backgroundColor: props.isChecked ? colors.baby_blue : colors.white,
    // Hack ~ https://stackoverflow.com/questions/8114657/how-to-style-the-parent-element-when-hovering-a-child-element
    // Turns off pointer events for parent but child elements can still accept them
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: colors.baby_blue,
    },
  }),
  boxLayout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: props => ({
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: 2.29,
    padding: props.isCompoundRoutines ? 5 : 0,
  }),
  checkboxMargins: {
    marginLeft: 0,
    marginRight: 10,
    // Can influence how parent element (listItem) can change color when
    // this element is being hovered over
    pointerEvents: 'auto',
  },
  thumbnail: {
    borderRadius: 6,
  },
  progressionRegressionView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    position: 'absolute',
    width: 76,
    height: 48,
    justifyContent: 'flex-end',
  },
  progressionRegressionIcon: {
    width: 17,
    height: 17,
    marginBottom: 3,
    marginRight: 3,
    marginLeft: 2,
  },
  imageContainer: {
    paddingLeft: 12,
    display: 'flex',
  },
});

const ExerciseScheduleItem = React.forwardRef((props, ref) => {
  const { exercise = {}, isChecked, onToggleCheckmark } = props;
  const { name, image_url: imageUrl } = exercise;
  const classes = useStyles(props);

  const renderProgressionRegressionIconImage = (exercise) => {
    const hasProgression = exercise?.progressions_regressions?.some(
      (ex) => ex.exerciseType === EXERCISE_TYPE.PROGRESSION,
    );
    const hasRegression = exercise?.progressions_regressions?.some(
      (ex) => ex.exerciseType === EXERCISE_TYPE.REGRESSION,
    );
  
    return (
      <Box className={classes.progressionRegressionView}>
        {hasProgression ? (
          <img
            src={progressionIcon}
            className={classes.progressionRegressionIcon}
            alt={'Prog'}
            onError={(e) => {
              e.target.src = progressionIcon;
            }}
          />
        ) : null}
        {hasRegression ? (
          <img
            src={regressionIcon}
            className={classes.progressionRegressionIcon}
            alt={'Reg'}
            onError={(e) => {
              e.target.src = regressionIcon;
            }}
          />
        ) : null}
      </Box>
    );
  };

  return (
    <ListItem ref={ref} className={classes.listItem}>
      <Box className={classes.boxLayout}>
        <Box className={classes.checkboxMargins}>
          <SquareCheckbox
            isChecked={isChecked}
            onToggle={(checkFlag) => onToggleCheckmark({ checkFlag, exercise })}
          />
        </Box>
        <div className={classes.imageContainer}>
        {!props.isCompoundRoutines && <img
          className={classes.thumbnail}
          width={75} height={48}
          alt='exercise-thumbnail'
          src={imageUrl || exerciseImagePlaceholder}
          style={{ marginRight: 23 }}
          onError={e => { e.target.src = exerciseImagePlaceholder; }}
        />}
        {exercise?.progressions_regressions?.length
            ? renderProgressionRegressionIconImage(exercise)
            : null}
        </div>
        <Typography className={classes.text}>
          {props.isCompoundRoutines ? exercise.title : name}
        </Typography>
      </Box>
    </ListItem>
  );
});
