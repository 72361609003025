import React, {useState} from 'react';
import PropTypes  from 'prop-types';

import {
  ChangeLink,
  ChangeLinkContainer,
  ContinueButton,
  InputDescription,
  InputGroup,
  InputLabel,
  PasswordTextField,
  LogoImgContainer,
  LoginForm,
  ServerError,
} from '../FormComponents';

import OvalTextInput from '../../Inputs/OvalTextInput';
import {ForgotPasswordLink, SignUpLink} from '../FormLinks';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';

import nasmApi from '../../../api/endpoints';

import { login } from '../../../reducers/currentUserReducer';
import { email as validateEmailInput,  password as validateUAPasswordRequirements } from '../../../util/validate';

import { LOGIN_TYPES, LOGIN_VIEWS, CREATE_ACCOUNT_ENABLED } from '../../../util/loginConstants';
import edgeLogo from '../../../resources/img-nasm-logo.svg';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import { clearSelectedProfile } from '../../../reducers/clubConnectReducer';

/**
 * @return {null|JSX}
 */
function LoginFormView (props) {
  const {
    email,
    onChangeEmail,
    onResetEmailError,
    emailError,
    setEmailError,
    checkEmail,
    switchEmail,
    loginView,
    loginType,
    onChangeToMigrateAccountView,
    onChangeToLinkAccountsView,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [showPasswordTextField, setShowPasswordTextField] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [serverError, setServerError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const [showAccountLockoutDialog, setShowAccountLockoutDialog] = useState(false);
  const [accountDialogText, setAccountDialogText] = useState('');
  const [showSignUpDialog,  setShowSignUpDialog] = useState(false);
  const [showClientDialog, setShowClientDialog] = useState(false);

  function onChange (e) {
    setPassword(e.target.value);
  }

  function onTogglePasswordVisibility() {
    setShowPass(!showPass);
  }

  function onSwitchEmail () {
    setShowPass(false);
    setPassword('');
    setShowPasswordTextField(false);
    setPasswordError('');
    switchEmail();
  }

  function createAccountOnEdge () {
    // Navigate to Edge SMS download page
    setShowSignUpDialog(false);
    window.open('https://nasm-edge.app.link/iiy9psLt5eb');
  }

  async function onLogin () {
    setLoading(true);
    await dispatch(login({ email, password }));
    setLoading(false);
    if(location.state && location.state.redirectFrom && !location.state.redirectFrom.includes('dashboard')){
      history.push(location.state.redirectFrom);
    }else{
      history.push('/clients');
    }
  }

  async function onMigrateEdgeAccountToUA () {
    // if existing EDGE password entered meets UA Password requirements
    // create UA user for EDGE user using the same EDGE password
    if(validateUAPasswordRequirements(password)) {
      setLoading(true);
      await nasmApi.register.migrateEdgeAccountToUA(email, password);
      await dispatch(login({ email, password }));
      setLoading(false);
      history.push('/clients');
    } else {
      onChangeToMigrateAccountView();
    }
  }

  async function onLinkUAAccountToEdge () {
    setLoading(true);
    await nasmApi.register.UATrainer(email, password);
    await dispatch(login({ email, password }));
    setLoading(false);
    history.push('/clients');
  }

  async function makeLoginRequest (email, password) {
    setServerError('');
    if(password.length === 0) {
      setPasswordError('Enter a password');
    } else {
      try {
        if (loginType === LOGIN_TYPES.LINKED_ACCOUNTS) {
          await onLogin();
        } else {

          if (loginType === LOGIN_TYPES.LEGACY_EDGE_ACCOUNT) {
            await nasmApi.register.verifyCredentials(email, password);
            await onMigrateEdgeAccountToUA();
          } else if (loginType === LOGIN_TYPES.UNLINKED_ACCOUNTS) {
            await nasmApi.register.verifyCredentials(email, password);
            onChangeToLinkAccountsView();
          } else if (loginType === LOGIN_TYPES.UA_ACCOUNT_ONLY) {
            await onLinkUAAccountToEdge();
          }

        }

      } catch (e) {
        if (e?.status === 500){
          setServerError(e?.data?.message?.description);
          setLoading(false);
          return;
        }
        let incomingErrorMsg = e?.data?.message?.title || e?.data?.message || e.message;
        const errorKey = e?.data?.error?.message?.key || e?.data?.message?.key;

        if(errorKey === 'last_attempt') {
          incomingErrorMsg = e?.data?.message?.description || e?.data?.error?.message?.description ||
            'Last attempt. Account will be temporarily locked.';
        }

        // Account Lockout
        if(errorKey === 'locked') {
          const incomingDescription = e?.data?.error?.description ||
            e?.error?.description || e?.data?.error?.message?.description ||
            'Your account is locked. Please try again later.';
          setAccountDialogText(incomingDescription);
          setShowAccountLockoutDialog(true);
        }

        setPassword('');
        setPasswordError(incomingErrorMsg);
        setLoading(false);
      }
    }
  }

  async function onSubmit (e) {
    e.preventDefault();

    onResetEmailError();
    setPasswordError('');

    if(showPasswordTextField) {
      // Assumes that Trainer is linked for EDGE and UA accounts
      dispatch(clearSelectedProfile());
      await makeLoginRequest(email, password);
    } else {
      if(validateEmailInput(email)) {
        setLoading(true);
        const newLoginType = await checkEmail(email);
        setLoading(false);

        if(![
          LOGIN_TYPES.UNEXPECTED_ERROR,
          LOGIN_TYPES.NEW_ACCOUNT,
          LOGIN_TYPES.CLIENT,
          LOGIN_TYPES.MISMATCHED_ACCOUNT_LINKS,
        ].includes(newLoginType)) {
          setShowPasswordTextField(true);
        }

        if(newLoginType === LOGIN_TYPES.CLIENT) {
          setShowClientDialog(true);
        }

        if (newLoginType === LOGIN_TYPES.NEW_ACCOUNT) {
          if (CREATE_ACCOUNT_ENABLED) {
            history.push('/create-account', { email });
          } else {
            setShowSignUpDialog(true);
          }
        }

      } else {
        setEmailError('Enter a valid email address');
      }
    }
  }


  if(loginView !== LOGIN_VIEWS.LOGIN_FORM) {
    return null;
  }

  return (
    <>
      <LogoImgContainer>
        <img
          alt='NASM EDGE - train with confidence'
          src={edgeLogo}
        />
      </LogoImgContainer>
      {!!serverError && <ServerError>{serverError}</ServerError>}
      <LoginForm onSubmit={onSubmit}>
        <InputGroup>
          <EmailLabel
            showPasswordTextField={showPasswordTextField}
            onSwitchEmail={onSwitchEmail}
          />
          <OvalTextInput
            placeholder='Email'
            value={email}
            onChange={onChangeEmail}
            readOnly={showPasswordTextField}
            disabled={showPasswordTextField}
            showError={!!emailError}
          />
          {!!emailError && <InputDescription>{emailError}</InputDescription>}
        </InputGroup>
        <InputGroup hide={!showPasswordTextField}>
          <InputLabel>Required</InputLabel>
          <PasswordTextField
            showPass={showPass}
            showError={!!passwordError}
            password={password}
            onChange={onChange}
            onTogglePasswordVisibility={onTogglePasswordVisibility}
          />
          {!!passwordError && <InputDescription>{passwordError}</InputDescription>}
        </InputGroup>
        <ContinueButton isLoading={isLoading} />
      </LoginForm>
      <SignUpLink showLink={!showPasswordTextField && CREATE_ACCOUNT_ENABLED} />
      <ForgotPasswordLink email={email} loginType={loginType} showLink={!!showPasswordTextField} />
      <ConfirmDialog
        hideCancelButton
        open={showAccountLockoutDialog}
        onClose={() => setShowAccountLockoutDialog(false)}
        handleConfirmAction={() => setShowAccountLockoutDialog(false)}
        title='Your account is temporarily locked'
        description={accountDialogText}
      />
      {/* TODO: need trainer sign up link here and verbiage change */}
      <ConfirmDialog
        actionButtonTitle='Get it'
        title='New to EDGE?'
        description={"NASM EDGE offers all the features you need to start and grow your" +
        " career as a Personal Trainer. Try it FREE! Download EDGE today."}
        open={showSignUpDialog}
        onClose={() => setShowSignUpDialog(false)}
        handleConfirmAction={createAccountOnEdge}
      />
      <ConfirmDialog
        hideCancelButton
        actionButtonTitle='OK'
        title='Client Access Coming Soon'
        description={"Hi there. We're currently working with your trainer to create the best experience possible and " +
        "at this time only allow for a trainer to sign in. Feel free to use the NASM EDGE mobile application " +
        "and when we open it up to amazing clients like you, we'll send you an update."}
        open={showClientDialog}
        onClose={() => setShowClientDialog(false)}
        handleConfirmAction={() => setShowClientDialog(false)}
      />
    </>
  );
}

function EmailLabel (props) {
  const { showPasswordTextField = false, onSwitchEmail } = props;

  if(showPasswordTextField) {
    return  (
      <ChangeLinkContainer>
        <ChangeLink onClick={onSwitchEmail}>
          Change
        </ChangeLink>
      </ChangeLinkContainer>
    );
  }

  return (
    <InputLabel>Required</InputLabel>
  );
}

LoginFormView.propTypes = {
  email: PropTypes.string,
  emailError: PropTypes.string,
  setEmailError: PropTypes.func,
  onChangeEmail: PropTypes.func,
  onResetEmailError: PropTypes.func,
  switchEmail: PropTypes.func,
  checkEmail: PropTypes.func,
  loginView: PropTypes.string.isRequired,
  loginType: PropTypes.string,
  onChangeToMigrateAccountView: PropTypes.func.isRequired,
  onChangeToLinkAccountsView: PropTypes.func.isRequired,
};

export default LoginFormView;