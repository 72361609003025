import { useEffect, useState } from 'react';
import axios from 'axios';
import nasmApi from '../../api/endpoints';

export default function useFetchExercisesList ({
  searchText,
  pageNumber,
  sizePerPage,
  exercisesFilter = '',
  // also known as body region
  muscleGroupIds = '',
  fitnessComponentIds = '',
  sortField = '',
  sortOrder = '',
  shouldFetch = true,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [exerciseData, setExerciseData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  // Clear out exercise data if new searchText is entered
  useEffect(() => {
    setExerciseData([]);
  }, [searchText, exercisesFilter, muscleGroupIds, fitnessComponentIds, sortField, sortOrder]);

  useEffect(() => {
    if (shouldFetch) {
      setLoading(true);
      setError(false);

      let isMounted = true;

      const searchParams = {
        page: pageNumber,
        size: sizePerPage,
        exercisesFilter: exercisesFilter.split(','),
      };

      if (searchText && searchText.length > 0) {
        searchParams.search = searchText;
      }

      if (muscleGroupIds.length > 0) {
        searchParams.muscleGrpIds = muscleGroupIds.split(',');
      }

      if (fitnessComponentIds.length > 0) {
        searchParams.fitnessCompIds = fitnessComponentIds.split(',');
      }

      if(!!sortField && sortField.length > 0) {
        searchParams.sortField = sortField;
      }

      if(!!sortOrder && sortOrder.length > 0) {
        searchParams.sortOrder = sortOrder;
      }

      const cancel = axios.CancelToken.source();
      nasmApi.nasmExercises
        .findExercises(searchParams, cancel.token)
        .then(data => {
          if (isMounted) {
            setLoading(false);

            if (!data.isRequestCancelled) {
              setHasMore(data.result.nextPage && data.result.nextPage.length > 0);
              setExerciseData(prevExerciseData => {
                return [
                  ...prevExerciseData,
                  ...data.result.exercises,
                ];
              });
            } else {
              setHasMore(false);
            }
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {
          setError(true);
          setLoading(false);
        });

      // Acts as a clean up function that gets called before executing the next effect
      // and runs before the component is removed from the UI
      return () => {
        isMounted = false;
        cancel.cancel('Exercise Search Request Cancelled');
      };
   }
  }, [
    searchText,
    pageNumber,
    sizePerPage,
    exercisesFilter,
    fitnessComponentIds,
    muscleGroupIds,
    sortField,
    sortOrder,
    shouldFetch,
  ]);

  return { loading, error, exerciseData, hasMore };
}
