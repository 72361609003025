import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import nasmApi from '../../../api/endpoints';
import { selectClient } from '../../../reducers/selectedClientReducer';
import { useFetchAllGoals, useFetchActivityLevels, useFetchAllMilestones } from '../../../hooks/users/GoalsHooks';
import { SAVE_STATES } from '../../../constants';

import GoalsDialog from '../../Dialogs/GoalsDialog';

function ManageGoals (props) {
  const { goalsOpen, onClose, client } = props;
  const { goals } = useFetchAllGoals();
  const { activityLevels } = useFetchActivityLevels();
  const {
    milestones,
    loading,
    getAllMilestones,
  } = useFetchAllMilestones(client.id);

  const dispatch = useDispatch();

  const [saveState, setSaveState] = useState(SAVE_STATES.CLICKABLE);
  const [largestOrdinal, setLargestOrdinal] = useState(0);
  const [incompleteMilestones, setIncompleteMilestones] = useState([]);
  const [completedMilestones, setCompletedMilestones] = useState([]);
  const [isAdding, setAdding] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isFtu, setFtu] = useState(false);

  const calculateLargestOrdinal = useCallback(() => {
    const incomplete = milestones.filter(m => !m.status);
    let maxOrdinal = 0;
    incomplete.forEach(milestone => {
      if (milestone.ordinal) {
        maxOrdinal = Math.max(maxOrdinal, milestone.ordinal);
      }
    });
    setLargestOrdinal(maxOrdinal);
  }, [milestones]);

  const createFtuExperience = useCallback(() => {
    const newMilestone = { name: '' };
    setFtu(true);
    setAdding(true);
    if(!incompleteMilestones.length){
      setIncompleteMilestones( prev => [newMilestone, ...prev]);
    }
  }, [incompleteMilestones]);

  useEffect(() => {
    if(!loading && milestones.length){
      setIncompleteMilestones(milestones.filter(m => !m.status));
      setCompletedMilestones(milestones.filter(m => !!m.status));
      calculateLargestOrdinal();
    }
    if (!loading && milestones.length === 0) {
      createFtuExperience();
    } else {
      setFtu(false);
      setAdding(false);
    }
  }, [loading, milestones, calculateLargestOrdinal, createFtuExperience]);

  const resetSaveState = () => {
    setSaveState(SAVE_STATES.CLICKABLE);
  };

  const createNewMilestone = () => {
    if (isAdding) return; // Prevent adding another milestone while one is being added
    const newMilestone = { name: '' };
    setAdding(true);
    setIncompleteMilestones(prev => [newMilestone, ...prev]);
  };  

  const saveNewMilestone = async (milestone) => {
    if (milestone.name.length === 0) { return; }
    try {
      const pendingMilestone = milestone;

      // Give the new milestone the largest ordinal + 1
      pendingMilestone.ordinal = largestOrdinal + 1;

      // remove prepended milestone and add it to the end
      setIncompleteMilestones(prev => [...prev.slice(1), pendingMilestone]);
      await nasmApi.goals.createMilestone(client.id, pendingMilestone);
      setFtu(false);
      getAllMilestones(client.id);
      setAdding(false);
      setSaveState(SAVE_STATES.CLICKABLE);
    } catch (e) {
        window.alert('Unable to save milestone. Please try again.');
    }
  };

  const saveEditedMilestone = async (milestone) => {
    try {
      await nasmApi.goals.updateMilestone(client.id, milestone);
      getAllMilestones(client.id);
      setSaveState(SAVE_STATES.CLICKABLE);
      setAdding(false);
    } catch (e) {
      window.alert('Unable to save milestone. Please try again.');
    }
  };

  const deleteMilestone = async (milestone) => {
    setDeleting(true);
    try {
      setIncompleteMilestones(prev => prev.filter(m => m.id !== milestone.id));
      if (milestone.id && milestone.name) {
        await nasmApi.goals.deleteMilestone(milestone.id);
        getAllMilestones(client.id);
      }
      setSaveState(SAVE_STATES.CLICKABLE);
      if (isAdding) {
        setAdding(false);
      }
    } catch (e) {
      window.alert('Unable to delete milestone. Please try again.');
    } finally {
      setDeleting(false);
    }
  };

  const reorderMilestones = async (srcIndex, destIndex) => {
    try {
      let largestOrdinal = 0;
      const tempMilestones = [...incompleteMilestones];

      // Swap milestone at source index with destination index
      const [draggedMilestone] = tempMilestones.splice(srcIndex, 1);
      tempMilestones.splice(destIndex, 0, draggedMilestone);

      // Reset ordinal for each milestone
      tempMilestones.forEach((milestone, index) => {
        tempMilestones[index].ordinal = index + 1;
        largestOrdinal = tempMilestones[index].ordinal;
      });

      // Update local state
      setIncompleteMilestones(tempMilestones);
      setLargestOrdinal(largestOrdinal);
      await nasmApi.goals.updateMilestones(client.id, tempMilestones);
      getAllMilestones(client.id);
      setSaveState(SAVE_STATES.CLICKABLE);
    } catch (e) {
      setSaveState(SAVE_STATES.ERROR);
      // If error, reset milestone order to previous order
      setIncompleteMilestones(incompleteMilestones);
      window.alert('Unable to reorder milestones. Please try again.');
    }
  };
  
  const markMilestoneAsComplete = async (milestone) => {
    if (!milestone.id) { return; }
    setSaveState(SAVE_STATES.CLICKABLE);
    const newMilestone = { ...milestone };
    if (!milestone.status) {
      // Marking milestone as complete
      newMilestone.status = true;
      milestone.ordinal = largestOrdinal;

      // Remove milestone from incomplete list and add to completed list
      setIncompleteMilestones(prev => [...prev.filter(m => m.id !== milestone.id)]);
      setCompletedMilestones(prev => [newMilestone, ...prev]);
    } else {
      // Marking milestone as incomplete
      newMilestone.status = false;
      newMilestone.ordinal = largestOrdinal + 1;

      // Remove milestone from completed list and add to incomplete list
      setCompletedMilestones(prev => [...prev.filter(m => m.id !== milestone.id)]);
      setIncompleteMilestones(prev => [...prev, newMilestone]);
    }
    try {
      await nasmApi.goals.updateMilestone(client.id, newMilestone);
      getAllMilestones(client.id);
    } catch (e) {
      setSaveState(SAVE_STATES.ERROR);
      window.alert('Unable to mark milestone as complete. Try again later.');
    }
  };

  const onSaveGoals = async (goalId, activityLevelId) => {
    try {
      setSaveState(SAVE_STATES.LOADING);

      // Update Focus and Activity Level
      const updatedClient = await nasmApi.users.updateClientUser({
        goal_id: goalId,
        activity_level_id: activityLevelId,
      }, client.id);

      await dispatch(selectClient(updatedClient.result));
      getAllMilestones(client.id);
      setSaveState(SAVE_STATES.SAVED);

    } catch (e) {
      setSaveState(SAVE_STATES.ERROR);
      window.alert('Unable to save goal information. Please try again.');
    }
  };

  return (
    <GoalsDialog
      open={goalsOpen}
      onClose={onClose}
      saveState={saveState}
      focusOptions={goals}
      activityLevelOptions={activityLevels}
      onSaveGoals={onSaveGoals}
      resetSaveState={resetSaveState}
      client={client}
      loading={loading}
      incompleteMilestones={incompleteMilestones}
      completedMilestones={completedMilestones}
      saveNewMilestone={saveNewMilestone}
      saveEditedMilestone={saveEditedMilestone}
      deleteMilestone={deleteMilestone}
      reorderMilestones={reorderMilestones}
      createNewMilestone={createNewMilestone}
      isAdding={isAdding}
      isDeleting={isDeleting}
      isFtu={isFtu}
      markMilestoneAsComplete={markMilestoneAsComplete}
    />
  );
}

export default ManageGoals;
