import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { HighlightSearchBar } from '../../components/Inputs';
import { useExercisesContext } from '../../contexts/ExercisesContext';
import { Box, Checkbox, CircularProgress, List, ListItem, Typography } from '@material-ui/core';
import thumbnailPlaceholder from '../../resources/image-exercise-placeholder@2x.png';
import { colors } from '../../styles';
import ExerciseListSkeleton from '../../components/LoadingStates/ExerciseListSkeleton';
import NoSearchResults from '../../components/EmptyStates/NoSearchResults';
import { useDebounce, usePaginationObserver } from '../../util/utilFunctions';

const useStyles = makeStyles({
  searchBar: {textAlign: 'center', margin: 20, marginTop: 0},
});

const exerciseItemStyles = makeStyles({
  recordContainer: {
    height: 80,
    borderBottom: '1px solid #e6e6e6',
    borderLeft: 'none',
    borderRight: 'none',
    '&:hover': {
      backgroundColor: colors.baby_blue,
      cursor: 'pointer',
    },
    '&:active': {
      filter: 'brightness(88%)',
      backgroundColor: colors.white,
    },
  },
  recordContainerSelected: {
    height: 80,
    borderBottom: '1px solid #b6bdc3',
    borderCollapse: 'collapse',
    backgroundColor: colors.baby_blue,
  },
  boxLayout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  imageContainer: {
    paddingLeft: 12,
    display: 'flex',
  },
  thumbnail: {
    borderRadius: 6,
    objectFit: 'cover',
  },
  nameContainer: {
    width: '80%',
    paddingLeft: 23,
    fontSize: 17,
    fontWeight: 500,
    color: colors.black,
  },
  arrowIconCont: {
    position: 'absolute',
    right: 12,
    bottom: 21,
  },
});

const exerciseListStyle = makeStyles({
  listContainer: {
    overflowY: 'auto',
    height: '88%',
    padding: 0,
  },
    exerciseListHeading: {
    border: `1px solid ${colors.divider_line_white}`,
    backgroundColor: colors.checkbox_grey,
    padding: '10px 20px',
  },
  ListHeadingText: {
    fontFamily: 'DMSans, sans-serif',
    fontWeight: 500,
    fontSize: 14,
  },
});

function Search (props)  {
  const {
    clearSearchText,
    searchText,
    handleSearch,

  } = props;

  const classes = useStyles();

  return (
    <Box className={classes.searchBar}>
      <HighlightSearchBar
        isClearable={searchText.length > 0}
        onClickClear={clearSearchText}
        value={searchText}
        onChange={handleSearch}
        fullWidth
      />
    </Box>
  );
}

function LoadingSpinner () {
  return (
    <ListItem
      key='loading spinner'
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <CircularProgress size={20} />
    </ListItem>
  );
}

const ExerciseItemWithRef = React.forwardRef((props, ref) => {
  return <ExerciseItem lastExerciseRecordRef={ref} {...props} />;
});

const ExerciseListWithRef = React.forwardRef((props, ref) => {
  return <ExerciseList lastExerciseRecordRef={ref} {...props} />;
});

const ExerciseItem = (props) => {
  const {
    isSelected,
    onChangeSelectedItem,
    exercise,
    setSelectedExercise,
  } = props;
  const classes = exerciseItemStyles();

  const onInvalidImg = (e) => {
    e.target.src = thumbnailPlaceholder;
  };

  const handleCick = (exercise) => {   
    setSelectedExercise(exercise);
    onChangeSelectedItem(exercise.id);
  };

  return (
    <ListItem
      ref={props.lastExerciseRecordRef}
      className={
        isSelected ? classes.recordContainerSelected : classes.recordContainer
      }
      onClick={() => handleCick(exercise)}
    >
      <Box className={classes.boxLayout}>
        <div className={classes.imageContainer}>
          <img
            width={75} height={48}
            alt='exercise-thumbnail'
            src={exercise.image_url || thumbnailPlaceholder}
            className={classes.thumbnail}
            onError={onInvalidImg}
          />
        </div>
        <div className={classes.nameContainer}>{exercise.name || 'Exercise Name'}</div>
      </Box>
      <Box className={classes.arrowIconCont}>
        <Checkbox
          checked={isSelected}
          color={'primary'}
          inputProps={{
            'aria-label': 'Checkbox',
          }}
        />
      </Box>
    </ListItem>
  );
};

const ExerciseList = (props) => {
  const {
    exerciseData,
    isLoading,
    selectedItem,
    setSelectedItem,
    setPageNumber,
    hasMore,
    setSelectedExercise,
    isCircuits,
    setSelectedCircuitsExercise,
  } = props;
  const classes = exerciseListStyle();

  const myExercises = exerciseData?.filter(
    (exercise) => !!exercise.owner_id,
  );

  const nasmExercises = exerciseData?.filter(
    (exercise) => !exercise.owner_id,
  );

  const rootElement = useRef();
  const lastExerciseRecordRef = usePaginationObserver({
    setPageNumber: setPageNumber,
    isLoading: isLoading,
    hasMoreDataToLoad: hasMore,
    rootElement: rootElement.current,
  });

  return (
    <>
      <List className={classes.listContainer} ref={rootElement}>
        {myExercises.length > 0 && (
          <>
            <Box className={classes.exerciseListHeading}>
              <Typography className={classes.ListHeadingText}>
                My Exercises
              </Typography>
            </Box>
            {myExercises.map((exercise, index) => {
               return <ExerciseItem
                  key={exercise.id}
                  isSelected={isCircuits?
                    setSelectedCircuitsExercise?.includes(exercise.id) :
                    selectedItem === exercise.id}
                  onChangeSelectedItem={setSelectedItem}
                  exercise={exercise}
                  setSelectedExercise={setSelectedExercise}
                />;
            })}
          </>
        )}

        {nasmExercises.length > 0 && (
          <Box ref={rootElement}>
            <Box className={classes.exerciseListHeading}>
              <Typography className={classes.ListHeadingText}>
                NASM Exercises
              </Typography>
            </Box>
            {nasmExercises.map((exercise, index) => {
              if (nasmExercises.length === index + 1) {
                return (
                  <ExerciseItemWithRef
                    ref={lastExerciseRecordRef}
                    key={exercise.id}
                    isSelected={isCircuits?
                      setSelectedCircuitsExercise?.includes(exercise.id) :
                      selectedItem === exercise.id}
                    onChangeSelectedItem={setSelectedItem}
                    exercise={exercise}
                    setSelectedExercise={setSelectedExercise}    
                  />
                );
              }
              return (
                <ExerciseItem
                  key={exercise.id}
                  isSelected={isCircuits?
                    setSelectedCircuitsExercise?.includes(exercise.id) :
                    selectedItem === exercise.id}
                   onChangeSelectedItem={setSelectedItem}
                  exercise={exercise}
                  setSelectedExercise={setSelectedExercise}
                />
              );
            })}
          </Box>
        )}

        {isLoading && <LoadingSpinner />}
      </List>
    </>
  );
};


function ExerciseListContent (props) {
  const {
    areSearchResultsNotFound,
    isInitialListLoading,
    exerciseData,
    lastExerciseRecordRef,
    isLoading,
    selectedItem,
    setSelectedItem,
    setPageNumber,
    hasMore,
    setSelectedExercise,
    isCircuits,
    setSelectedCircuitsExercise,
  } = props;

  if (isInitialListLoading) {
    return <ExerciseListSkeleton />;
  }

  if (areSearchResultsNotFound) {
    return <NoSearchResults />;
  }

  return (
    <ExerciseListWithRef
      exerciseData={exerciseData}
      lastExerciseRecordRef={lastExerciseRecordRef}
      isLoading={isLoading}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      setPageNumber={setPageNumber}
      hasMore={hasMore}
      setSelectedExercise={setSelectedExercise}
      isCircuits={isCircuits}
      setSelectedCircuitsExercise={setSelectedCircuitsExercise}
    />
  );
}

export default function ChooseExerciseList (props) {
  const {setSelectedExercise, isCircuits, setSelectedCircuitsExercise} = props;
  const classes = useStyles();

  const [searchText, setSearchText] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedItem, setSelectedItem] = useState('');
  const resetSelection = () => setSelectedItem('');

  // Delay search until user is done typing in search bar
  const debouncedSearch = useDebounce(searchText, 500);

  const {
    fetchExercises,
    clearExercises,
    exercises: exerciseData,
    loading,
    hasMore,
    // pageNumber: newPageNumber,
  } = useExercisesContext();

  // Reset exercise list when search field change
  useEffect(() => {
    clearExercises();
  }, [
    clearExercises,
    debouncedSearch,
  ]);

  useEffect(() => {
    fetchExercises({
      pageNumber,
      searchText: debouncedSearch,
      sizePerPage: 60,
      activeExerciseFilter:'',
    });
  }, [
    fetchExercises,
    pageNumber,
    debouncedSearch,
  ]);

  function handleSearch (e) {
    setSearchText(e.target.value);
    setPageNumber(1);
    resetSelection();
  }

  const clearSearchText = () => {
    setSearchText('');
    setPageNumber(1);
  };

  const areSearchResultsNotFound = !loading && exerciseData.length === 0;
  const isInitialListLoading = loading && exerciseData.length === 0;

  return (
    <Box style={{ height: 'inherit' }}>
      <Box className={classes.searchBar}>
        <Search
          clearSearchText={clearSearchText}
          searchText={searchText}
          handleSearch={handleSearch}
        />
      </Box>
      <Box>
        <Box>
          <ExerciseListContent
            areSearchResultsNotFound={areSearchResultsNotFound}
            isInitialListLoading={isInitialListLoading}
            exerciseData={exerciseData}
            isLoading={loading}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setPageNumber={setPageNumber}
            hasMore={hasMore}
            setSelectedExercise={setSelectedExercise}
            isCircuits={isCircuits}
            setSelectedCircuitsExercise={setSelectedCircuitsExercise}
          />
        </Box>
      </Box>
    </Box>
  );
}
